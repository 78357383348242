import React from "react";

export const File = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M0 4.94975C0 4.06722 7.45058e-09 3.62595 0.06935 3.25839C0.37464 1.64031 1.64031 0.37464 3.25839 0.06935C3.62595 7.45058e-09 4.06722 0 4.94975 0C5.33642 0 5.52976 0 5.71557 0.01738C6.51665 0.09229 7.27652 0.40704 7.89594 0.92051C8.0396 1.03961 8.1763 1.17633 8.4497 1.44975L9 2C9.8158 2.81578 10.2237 3.22367 10.7121 3.49543C10.9804 3.64471 11.2651 3.7626 11.5604 3.84678C12.0979 4 12.6747 4 13.8284 4H14.2021C16.8345 4 18.1506 4 19.0062 4.76946C19.0849 4.84024 19.1598 4.91514 19.2305 4.99383C20 5.84935 20 7.16554 20 9.7979V12C20 15.7712 20 17.6569 18.8284 18.8284C17.6569 20 15.7712 20 12 20H8C4.22876 20 2.34315 20 1.17157 18.8284C1.19209e-07 17.6569 0 15.7712 0 12V4.94975Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 8C10.25 7.58579 10.5858 7.25 11 7.25H16C16.4142 7.25 16.75 7.58579 16.75 8C16.75 8.4142 16.4142 8.75 16 8.75H11C10.5858 8.75 10.25 8.4142 10.25 8Z"
        fill="#4E6BF2"
      />
      <path
        d="M14.9856 1.02094C14.8321 1 14.6492 1 14.2835 1H10L10.3699 1.38312C11.0359 2.07299 11.2919 2.33051 11.5877 2.50096C11.7594 2.5999 11.9415 2.67804 12.1304 2.73383C12.4559 2.82993 12.8128 2.83538 13.7546 2.83538H14.089C15.0914 2.83536 15.8995 2.83535 16.5389 2.91862C16.6984 2.93939 16.8521 2.96582 17 3C16.8144 1.96313 16.0043 1.15985 14.9856 1.02094Z"
        fill="white"
      />
    </svg>
  );
};
