import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { useStore } from '../../context/GlobalContext';
import { useUpload } from '../../UploadContext';

const ClinicalProcessingNotification = () => {
  const { notify } = useStore();
  const { clinicalUploadsInProgress } = useUpload()

  return (
    <>
      {clinicalUploadsInProgress > 0 && <div className={`ready-to-review-popup ${notify ? "active" : ""}`}>
        <div className="content-popup">
          <span>
            <FaCheck />
          </span>
          <p>
            <b>Processing {clinicalUploadsInProgress} clinical file(s)...</b>
          </p>

        </div>
      </div>}
    </>

  )
}

export default ClinicalProcessingNotification


