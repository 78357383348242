// Profile.js
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);

  useEffect(() => {
    const getUserMetadata = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            audience: 'https://dev-pa40elfwthwni2c2.us.auth0.com/api/v2/',
            scope: 'read:current_user update:current_user_metadata',
          });
          const response = await fetch(`https://dev-pa40elfwthwni2c2.us.auth0.com/api/v2/users/${user.sub}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const data = await response.json();
          setUserMetadata(data);
        } catch (error) {
          console.error('Error fetching user metadata:', error);
        }
      };

    if (isAuthenticated) {
      getUserMetadata();
    }
  }, [isAuthenticated, getAccessTokenSilently, user]);

  const handlePasswordUpdate = async (newPassword) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://dev-pa40elfwthwni2c2.us.auth0.com/api/v2/',
        scope: 'update:current_user_metadata',
      });

      await fetch(`https://dev-pa40elfwthwni2c2.us.auth0.com/api/v2/users/${user.sub}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: newPassword }),
      });

      // Password updated successfully
      console.log('Password updated successfully');
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  if (!isAuthenticated) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <div>
      <h2>Profile</h2>
      {userMetadata ? (
        <div>
          <p>Name: {userMetadata.name}</p>
          <p>Email: {userMetadata.email}</p>
          {/* Display other profile details */}
          <button onClick={() => handlePasswordUpdate('newPassword')}>Update Password</button>
        </div>
      ) : (
        <p>Loading profile...</p>
      )}
    </div>
  );
};

export default Profile;