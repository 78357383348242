// components/DeleteModal.js
import React from "react";
import Modal from "react-modal";
import Trash from "../assets/icons/Trash";
import { RxCross2 } from "react-icons/rx";
import LoaderSpin from "./LoaderSpin/LoaderSpin";

const DeleteModal = ({ isOpen, onClose, onConfirm, isLoading = false }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Confirm Delete"
      className="new-brand-modal"
      overlayClassName="new-brand-modal-overlay"
    >
      <div className="modal-logo">
        <img src="/solstice.png" alt="logo" />{" "}
      </div>
      <p>Are you sure you want to delete this file?</p>
      <div className="modal-buttons">
        <button
          disabled={isLoading}
          className="delete-button"
          onClick={onConfirm}
        >
          {isLoading ? (
            <LoaderSpin style={{ height: "30px", width: "30px" }} />
          ) : (
            <>
              <Trash /> Yes delete
            </>
          )}
        </button>
        <button
          disabled={isLoading}
          className="cancel-button"
          onClick={onClose}
        >
          <RxCross2 /> Cancel
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
