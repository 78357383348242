import React, { useState, useEffect, useContext } from "react";
import Navbar from "./Navbar";
import { FaTrashAlt, FaChevronDown } from "react-icons/fa";
import Header from "./Header";
import "./BrandRules.css";
import { BrandContext } from "./BrandContext";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import Modal from "react-modal";
import { useNavbarContext } from "./context/NavbarContext";

Modal.setAppElement("#root");

const foundationalRules = [
  {
    title: "True Statement of Information",
    description: `Ensures that all claims present a true statement relating to side effects, contraindications, and effectiveness. Ensures that all claims of effectiveness must be supported by evidence from clinical sources.`,
  },
  {
    title: "Fair Balance",
    description: `Ensures that there is a fair balance between the amount of information on side effects/contraindications and effectiveness. Evaluates the whole document to ensure claims about side effects/contraindications are present if claims about effectiveness are made. When evaluating, note that fair balance does not need to be present for each line about effectiveness. Note that a document meets fair balance as long as statements about side effects/contraindications are present in the document in any text format, size, or color.`,
  },
  {
    title: "Prominence of Risk Information",
    description: `Ensures that risk information is presented with prominence and readability. Ensures that the main risks are outlined and presented, though they don’t need to include all specificity, details, and statistics from the clinical information.`,
  },
  {
    title: "Consistency with Approved Labeling",
    description: `Ensures information in the ad is consistent with the approved labeling. Ensures that all claims about the drug are verified, are accurate, and align with the statements contained in the clinical information.`,
  },
  {
    title: "No Misleading Claims",
    description: `Ensures that the ad must not contain misleading claims about the drug’s effectiveness or safety. Ensures that claims in the ad do not exaggerate effectiveness or safety in a way not supported by clinical information.`,
  },
  {
    title: "Comparative Claims",
    description: `Ensures that claims in the ad comparing the drug to another drug are supported by substantial evidence. If a claim compares the drug to another drug, Solstice ensures that clinical information explicitly states the benefits of one drug over another in a certain aspect or subpopulation.`,
  },
  {
    title: "No Unapproved Uses",
    description: `Ensures that the ad does not recommend or suggest uses not approved in the labeling. Ensures that the drug is only advertised for conditions or users for which it has been approved.`,
  },
];

const BrandRules = () => {
  const { selectedBrand } = useContext(BrandContext);
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();

  const [rules, setRules] = useState([]);
  const [ruleName, setRuleName] = useState("");
  const [ruleDescription, setRuleDescription] = useState("");
  const [riskTolerance, setRiskTolerance] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expandedRuleIndex, setExpandedRuleIndex] = useState(null);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchRules = async () => {
    if (isAuthenticated && selectedBrand) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        let roles = decodedToken["https://solsticehealth.co/roles"];
        roles = roles[0];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/rules?teamId=${roles}&brandName=${selectedBrand.label}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Fetched rules:", data);
        setRules(data || []);

        // Fetch risk tolerance from the server
        const riskResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/riskTolerance?teamId=${roles}&brandName=${selectedBrand.label}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (riskResponse.ok) {
          const riskData = await riskResponse.json();
          setRiskTolerance(riskData.riskTolerance || 5);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching rules:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchRules();
  }, [isAuthenticated, getAccessTokenSilently, selectedBrand]);

  const handleAddRule = async () => {
    if (isAuthenticated && ruleName && ruleDescription) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        let roles = decodedToken["https://solsticehealth.co/roles"];
        roles = roles[0];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/rules`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              teamId: roles,
              brandName: selectedBrand.label,
              ruleName: ruleName,
              ruleDescription: ruleDescription,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        setRuleName("");
        setRuleDescription("");
        fetchRules();
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error adding rule:", error);
      }
    }
  };

  const handleDeleteRule = async (ruleId) => {
    if (isAuthenticated) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/rules/${ruleId}?teamId=${roles}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        fetchRules();
      } catch (error) {
        console.error("Error deleting rule:", error);
      }
    }
  };

  const handleRiskToleranceChange = async (e) => {
    const newRiskTolerance = Number(e.target.value);
    setRiskTolerance(newRiskTolerance);

    if (isAuthenticated) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        let decodedToken = jwtDecode(idToken.__raw);
        let roles = decodedToken["https://solsticehealth.co/roles"];
        roles = roles[0];

        await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/riskTolerance`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              teamId: roles,
              brandName: selectedBrand.label,
              riskTolerance: newRiskTolerance,
            }),
          }
        );
      } catch (error) {
        console.error("Error updating risk tolerance:", error);
      }
    }
  };

  const toggleRuleDescription = (index) => {
    setExpandedRuleIndex(expandedRuleIndex === index ? null : index);
  };

  const { isOpen } = useNavbarContext();

  return (
    <div className={`content_container ${!isOpen ? "container-expend" : ""}`}>
      <Header />
      <Navbar />
      <div className="container">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            

            <div className="header">
              <h2>Brand Rules</h2>
              <button
                className="add-rule-button"
                onClick={() => setIsModalOpen(true)}
              >
                Add New Rule
              </button>
            </div>

            <div className="rules-list">
              {rules.length > 0 ? (
                rules.map((rule) => (
                  <div key={rule.id} className="rule-item">
                    <div className="rule-details">
                      <h3>{rule.rule_name}</h3>
                      <p>{rule.rule_description}</p>
                    </div>
                    <div className="rule-actions">
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteRule(rule.id)}
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>No rules defined yet.</p>
              )}
            </div>

            <div className="foundational-rules">
              <h3>Foundational Rules</h3>
              {foundationalRules.map((rule, index) => (
                <div
                  key={index}
                  className="rule-item foundational-rule"
                  onClick={() => toggleRuleDescription(index)}
                >
                  <div className="rule-details">
                    <h4>{rule.title}</h4>
                    {expandedRuleIndex === index && <p>{rule.description}</p>}
                  </div>
                  <FaChevronDown className="expand-icon" />
                </div>
              ))}
              <p>
                <strong>
                  If you would like the foundational rules to be changed, please
                  contact support@solsticehealth.co.
                </strong>
              </p>
            </div>
          </>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Add New Rule"
        className="new-rule-modal"
        overlayClassName="new-rule-modal-overlay"
      >
        <h2>Add New Rule</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <label>
            Rule Name:
            <input
              type="text"
              value={ruleName}
              onChange={(e) => setRuleName(e.target.value)}
              required
            />
          </label>
          <label>
            Rule Description:
            <textarea
              value={ruleDescription}
              onChange={(e) => setRuleDescription(e.target.value)}
              required
            />
          </label>
          <button type="button" onClick={handleAddRule}>
            Add Rule
          </button>
          <button type="button" onClick={() => setIsModalOpen(false)}>
            Cancel
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default BrandRules;
