import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import Navbar from "./Navbar";
import { FaTrashAlt, FaChevronRight } from "react-icons/fa";
import { GoChevronDown, GoChevronUp } from "react-icons/go";

import Header from "./Header";
import "./ExistingBrand.css";
import { useUpload } from "./UploadContext";
import { BrandContext } from "./BrandContext";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { jwtDecode } from "jwt-decode"; // Correct import
import { useNavbarContext } from "./context/NavbarContext";
import DeleteModal from "./components/DeleteModal";
import Calendar from "./assets/icons/Calendar";
import Action from "./assets/icons/Action";
import { File as FileIcon } from "./assets/icons/File";
import axios from "axios";
import Edit from "./assets/icons/Edit";
import TableLoader from "./components/TableLoader/TableLoader";
import { sorting } from "./utils/helpers";
import { useStore } from "./context/GlobalContext";
import PaginatedItems from "./components/PaginatedItems/PaginatedItems";
import Eye from "./assets/icons/Eye";
import LoaderSpin from "./components/LoaderSpin/LoaderSpin";
import { RxCross1 } from "react-icons/rx";

Modal.setAppElement("#root");

const ExistingBrand = () => {
  const fileInputRef = useRef(null);
  const { clinicalUploadsInProgress, addUpload, removeUpload } = useUpload(); // Use the upload context

  const [designFiles, setDesignFiles] = useState([]);
  const [clinicalFiles, setClinicalFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileTypeModalIsOpen, setFileTypeModalIsOpen] = useState(false);
  const { selectedBrand } = useContext(BrandContext);
  const [numPages, setNumPages] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [tableLoading, setLoading] = useState(false);
  const [documentsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [detailLoading, setDetailLoading] = useState({
    loading: false,
    id: "",
  });

  const [sort, setSort] = useState({
    design: { dir: "", column: "" },
    clinic: { dir: "", column: "" },
  });

  const [eventLoading, setEventLoading] = useState({
    save: false,
    delete: false,
  });
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const [viewDetail, setViewDetail] = useState(false);

  const { isOpen } = useNavbarContext();
  const { setNotify } = useStore();
  const [progressModalIsOpen, setProgressModalIsOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const detailRef = useRef(null);
  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const fetchFiles = useCallback(async () => {
    if (!isAuthenticated || !selectedBrand) return;
    setSelectedFile(null);

    // Check if data is cached
    const cacheKey = `files-${selectedBrand?.label}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      const { designFiles, clinicalFiles, timestamp } = JSON.parse(cachedData);
      const cacheExpiration = 1000 * 60 * 10; // Cache expiration time: 10 minutes

      if (Date.now() - timestamp < cacheExpiration) {
        setDesignFiles(designFiles);
        setClinicalFiles(clinicalFiles);
        return; // Use cached data, skip fetching
      }
    }

    let controller;
    let timeoutId;
    setLoading(true);
    try {
      // Fetch token and ID token concurrently
      const [token, idToken] = await Promise.all([
        getAccessTokenSilently(),
        getIdTokenClaims(),
      ]);

      const roles = jwtDecode(idToken.__raw)["https://solsticehealth.co/roles"];

      controller = new AbortController();
      timeoutId = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/files?teamId=${roles}&brandName=${selectedBrand.label}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          signal: controller.signal,
        }
      );

      clearTimeout(timeoutId); // Clear timeout if the request succeeds

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();

      if (data?.designFiles && data?.clinicalFiles) {
        setDesignFiles(data.designFiles);
        setClinicalFiles(data.clinicalFiles);

        // Cache the data with a timestamp
        localStorage.setItem(
          cacheKey,
          JSON.stringify({
            designFiles: data.designFiles,
            clinicalFiles: data.clinicalFiles,
            timestamp: Date.now(),
          })
        );
      } else {
        console.error(
          "Invalid response data format. Expected an object with designFiles and clinicalFiles arrays."
        );
        setDesignFiles([]);
        setClinicalFiles([]);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.error("Request timed out");
      } else {
        console.error("Error fetching files:", error);
      }
      setDesignFiles([]);
      setClinicalFiles([]);
    } finally {
      setLoading(false);
      // Ensure cleanup
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (controller) {
        controller.abort();
      }
    }
  }, [
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    selectedBrand,
  ]);

  useEffect(() => {
    if (clinicalUploadsInProgress > 0) {
      setNotify(true);
    }
  }, [clinicalUploadsInProgress]);

  useEffect(() => {
    fetchFiles();
  }, [selectedBrand?.label, fetchFiles]);


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFileSelect = (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files) {
      console.log("File selected:", files);

      if (!fileType) {
        setFileTypeModalIsOpen(true);
        clearFileInput();
        return;
      }
      handleFileUpload(files);
      clearFileInput();
    }
  };


  const handleFileUpload = async (files) => {
    if (isAuthenticated) {
      try {
        const formData = new FormData();
  
        // Check if `files` is a single File object
        if (files instanceof File) {
          console.log("Single file to upload:", files.name);
          formData.append('file', files);  // Append the single file
        } 
        // If `files` is an array-like FileList
        else if (files instanceof FileList) {
          const totalFiles = files.length;
          console.log("Total files to upload:", totalFiles);
  
          for (let i = 0; i < totalFiles; i++) {
            console.log("Appending file:", files[i].name);
            formData.append('file', files[i]);
          }
        }
  
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];
  
        formData.append("user", user.sub);
        formData.append("fileType", fileType);
        formData.append("email", user.email);
        formData.append("uploadedBy", user.name);
        formData.append("brandName", selectedBrand.label);
        formData.append("teamId", roles);

        setProgressModalIsOpen(true);
        setProgress(0);
  

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/upload`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData, // Send the FormData with the file(s)
          }
        );
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Upload failed");
        }
  
        const result = await response.json();
        const filesData = formData.getAll("file") || [];
        const uploadIds = result.uploadIds || [];

        for (let i = 0; i < filesData.length; i++) {
          if (i < uploadIds.length) {
            addUpload(uploadIds[i], filesData[i].name, fileType);
          }
        }
        // Initialize progress tracking
        let completedUploads = 0;
        const totalUploads = uploadIds.length;

        // Poll upload status for each upload ID
        for (let i = 0; i < uploadIds.length; i++) {
          await pollUploadStatus(uploadIds[i], token, async () => {
            completedUploads++;
            // Update progress based on completed uploads
            const progress = Math.floor(
              (completedUploads / totalUploads) * 100
            );
            setProgress(progress);

            // Close modal when all uploads are complete
            if (completedUploads === totalUploads) {
              setNotify(false);
              setProgressModalIsOpen(false);
              // Invalidate cache after uploading
              localStorage.removeItem(`files-${selectedBrand.label}`);
              await fetchFiles();
              toast.success(`Files has been uploaded!`, {
                autoClose: false,
                closeButton: true,
                closeOnClick: true,
              });
            }
          });
        }
        setFileType("");
        clearFileInput();
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error(`Error uploading file: ${error.message}`);
      }
    }
  };

  const pollUploadStatus = async (uploadId, token, onProgress) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/upload-status/${uploadId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch upload status");
      }
  
      const status = await response.json();
      if (status?.progress && status?.progress <= 100) {
        setProgress((pre) => Math.max(pre, status?.progress));

        if (status.status === "complete") {
          removeUpload(uploadId);
          onProgress();
        } else if (status.status === "error") {
          toast.error(
            `Error processing file: ${status.error_message || "Unknown error"}`
          );
          removeUpload(uploadId);
          onProgress();
        } else if (status.status === "processing") {
          setTimeout(() => pollUploadStatus(uploadId, token, onProgress), 5000);
        }
      }
    } catch (error) {
      console.error("Error polling upload status:", error);
      toast.error("Error checking upload status");
      removeUpload(uploadId);
      onProgress();
    }
  };

  const handleDelete = (file, type) => {
    setFileToDelete(file);
    setDeleteModalIsOpen(true);
    setFileType(type);
  };

  const removeFileFromLocalStorage = (fileIdToRemove, key) => {
    const cacheKey = `files-${selectedBrand.label}`;

    const cachedData = localStorage.getItem(cacheKey);
    if (!cachedData) {
      return; // Exit if there's no cached data
    }

    const parsedData = JSON.parse(cachedData);
    const fileKey = `${key}Files`;
    const files = parsedData[fileKey];

    // Filter out the file to be removed
    const updatedFiles = files?.filter(({ id }) => id !== fileIdToRemove) || [];

    // Prepare the updated cached data
    const updatedData = {
      ...parsedData,
      [fileKey]: updatedFiles,
      timestamp: Date.now(),
    };

    // Update local storage with the new data
    localStorage.setItem(cacheKey, JSON.stringify(updatedData));
  };
  const confirmDelete = async () => {
    if (fileToDelete) {
      setEventLoading((prev) => ({ ...prev, delete: true }));
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];
        const fileName = fileToDelete?.name;

        await axios(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/delete-file?fileName=${fileName}&fileType=${fileType}&teamId=${roles}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        removeFileFromLocalStorage(fileToDelete?.id, fileType);
        setDeleteModalIsOpen(false);
        setFileToDelete(null);
        fetchFiles();
        toast.success(`File has been deleted!`);
      } catch (error) {
        console.error("Error deleting file:", error);
      } finally {
        setEventLoading((prev) => ({ ...prev, delete: false }));
      }
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return `${bytes} B`;
    } else if (bytes < 1024 ** 2) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else if (bytes < 1024 ** 3) {
      return `${(bytes / 1024 ** 2).toFixed(2)} MB`;
    } else {
      return `${(bytes / 1024 ** 3).toFixed(2)} GB`;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const files = event.dataTransfer.files; // Get the dropped files
    if (files && files.length > 0) {
      console.log("Files dropped:", files);

      if (!fileType) {
        setFileTypeModalIsOpen(true); // Prompt the user for file type if it's not set
        return;
      }

      handleFileUpload(files, fileType); // Upload the files with file type
    }
  };
  

  const handleFileClick = async (file) => {
    if (isAuthenticated) {
      setDetailLoading((pre) => ({ ...pre, loading: true, id: file.id }));
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/file?fileName=${file.name}&fileType=clinical`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setViewDetail(true);
        setSelectedFile(url);
      } catch (error) {
        console.error("Error fetching file:", error);
      } finally {
        setDetailLoading((pre) => ({ ...pre, loading: false, id: "" }));
      }
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const handleSorting = (type = "design", columnName, direction) => {
    const isDesign = type === "design";
    const data = isDesign ? designFiles : clinicalFiles;
    const sortedData = sorting({
      arr: data,
      sortParam: columnName,
      sortOrder: direction,
    });
    console.log(data, columnName, direction);

    setSort((pre) => ({
      ...pre,
      [type]: { dir: direction, column: columnName },
    }));
    if (isDesign) {
      setDesignFiles(sortedData);
    } else {
      setClinicalFiles(sortedData);
    }
  };

  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;


  const currentClinicalDocuments = clinicalFiles?.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );



  const currentDesignDocuments = designFiles?.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );

  return (
    <div className={`content_container ${!isOpen ? "container-expend" : ""}`}>
      <Header />
      <Navbar />
      <div className="container">
        <div className="header">
          <h2>Brand Materials</h2>
        </div>
        <p>Documents that have been uploaded as part of this brand.</p>

        <div className="file-type-selector">
          <label className="radio-label">
            <input
              type="radio"
              value="design"
              checked={fileType === "design"}
              onChange={(e) => setFileType(e.target.value)}
            />
            <span className="radio-custom"></span>
            <span className="radio-label-text">Design</span>
          </label>
          <label className="radio-label">
            <input
              type="radio"
              value="clinical"
              checked={fileType === "clinical"}
              onChange={(e) => setFileType(e.target.value)}
            />
            <span className="radio-custom"></span>
            <span className="radio-label-text">Clinical</span>
          </label>
        </div>

        <div
          className="file-drop"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <label
            htmlFor="file-input"
            onClick={(e) => {
              if (!fileType) {
                e.preventDefault();
                setFileTypeModalIsOpen(true);
              }
            }}
            className="file-drop-content"
          >
            <div className="file-drop-zone">
              <div className="file-drop-icon">
                <img src="/file-upload-icon.png" alt="File Drop Icon" />
              </div>
              <input
                type="file"
                id="file-input"
                accept=".pdf"
                style={{ display: "none" }}
                onChange={handleFileSelect}
                ref={fileInputRef}
                multiple="multiple"
              />
              <div className="file-label">
                <span className="file-label-text">
                  Please upload existing content below to review
                </span>
              </div>
            </div>
          </label>
        </div>
        <div className="table-section">
          <div className="table-header">
            <h3>Design Elements</h3>
          </div>
          <div className="search-container"></div>
          <div className="table-responsive">
            <table className="material-table">
              <thead>
                <tr>
                  <th>
                    <div className="table-heading-item">
                      <FileIcon />
                      <span> Document Name</span>
                      <div className="sort-icons">
                        <GoChevronUp
                          className={`${sort.design.dir === "asc" &&
                            sort.design.column === "name" &&
                            "active"
                            }`}
                          onClick={() => handleSorting("design", "name", "asc")}
                        />
                        <GoChevronDown
                          className={`${sort.design.dir === "desc" &&
                            sort.design.column === "name" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("design", "name", "desc")
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="table-heading-item">
                      <Calendar />
                      Date Created
                      <div className="sort-icons">
                        <GoChevronUp
                          className={`${sort.design.dir === "asc" &&
                            sort.design.column === "date_uploaded" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("design", "date_uploaded", "asc")
                          }
                        />
                        <GoChevronDown
                          className={`${sort.design.dir === "desc" &&
                            sort.design.column === "date_uploaded" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("design", "date_uploaded", "desc")
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="table-heading-item">
                      <Edit />
                      Uploaded By
                      <div className="sort-icons">
                        <GoChevronUp
                          className={`${sort.design.dir === "asc" &&
                            sort.design.column === "uploaded_by" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("design", "uploaded_by", "asc")
                          }
                        />
                        <GoChevronDown
                          className={`${sort.design.dir === "desc" &&
                            sort.design.column === "uploaded_by" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("design", "uploaded_by", "desc")
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="existing_brand-action">
                    <Action />
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!tableLoading || currentDesignDocuments?.length ? (
                  currentDesignDocuments.map((file, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {file.name}
                      </td>
                      <td>{formatDate(file.date_uploaded)}</td>
                      <td>
                        <div className="user-info">
                          <span>{file.uploaded_by}</span>
                        </div>
                      </td>
                      <td>
                        <div className="action-buttons exist-brand">
                          <button
                            className="delete-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(file, "design");
                            }}
                          >
                            <FaTrashAlt />
                          </button>

                          <button
                            disabled={detailLoading.loading}
                            className="view-button"
                            onClick={() => handleFileClick(file)}
                          >
                            {detailLoading.loading &&
                              detailLoading.id === file.id ? (
                              <div className="view-detail-loader">
                                <LoaderSpin />
                              </div>
                            ) : (
                              <Eye />
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableLoader totalColumn={4} />
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-section">
          <div className="table-header">
            <h3>Clinical Elements</h3>
          </div>
          <div className="table-responsive">
            <table className="material-table">
              <thead>
                <tr>
                  <th>
                    <div className="table-heading-item">
                      <FileIcon />
                      Document Name
                      <div className="sort-icons">
                        <GoChevronUp
                          className={`${sort.clinic.dir === "asc" &&
                            sort.clinic.column === "name" &&
                            "active"
                            }`}
                          onClick={() => handleSorting("clinic", "name", "asc")}
                        />
                        <GoChevronDown
                          className={`${sort.clinic.dir === "desc" &&
                            sort.clinic.column === "name" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("clinic", "name", "desc")
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="table-heading-item">
                      <Calendar />
                      Date Created
                      <div className="sort-icons">
                        <GoChevronUp
                          className={`${sort.clinic.dir === "asc" &&
                            sort.clinic.column === "date_uploaded" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("clinic", "date_uploaded", "asc")
                          }
                        />
                        <GoChevronDown
                          className={`${sort.clinic.dir === "desc" &&
                            sort.clinic.column === "date_uploaded" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("clinic", "date_uploaded", "desc")
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="table-heading-item">
                      <Edit />
                      Uploaded By
                      <div className="sort-icons">
                        <GoChevronUp
                          className={`${sort.clinic.dir === "asc" &&
                            sort.clinic.column === "uploaded_by" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("clinic", "uploaded_by", "asc")
                          }
                        />

                        <GoChevronDown
                          className={`${sort.clinic.dir === "desc" &&
                            sort.clinic.column === "uploaded_by" &&
                            "active"
                            }`}
                          onClick={() =>
                            handleSorting("clinic", "uploaded_by", "desc")
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="existing_brand-action">
                    <Action />
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!tableLoading || currentClinicalDocuments.length ? (
                  currentClinicalDocuments.map((file, index) => (
                    <tr key={index}>
                      <td
                        style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                      >
                        {file.name}
                      </td>
                      <td>{formatDate(file.date_uploaded)}</td>
                      <td>
                        <div className="user-info">
                          <span>{file.uploaded_by}</span>
                        </div>
                      </td>
                      <td>
                        <div className="action-buttons exist-brand">
                          <button
                            className="delete-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(file, "clinical");
                            }}
                          >
                            <FaTrashAlt />
                          </button>
                          <button
                            disabled={detailLoading.loading}
                            className="view-button"
                            onClick={() => handleFileClick(file)}
                          >
                            {detailLoading.loading &&
                              detailLoading.id == file.id ? (
                              <div className="view-detail-loader">
                                <LoaderSpin />
                              </div>
                            ) : (
                              <Eye />
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableLoader totalColumn={4} />
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <PaginatedItems
              itemsPerPage={documentsPerPage}
              setPage={paginate}
              totalRecords={clinicalFiles?.length}
            />
          </div>
        </div>
      </div>

      {/* View detail modal */}

      <Modal
        isOpen={viewDetail}
        onRequestClose={() => setViewDetail(false)}
        contentLabel="Select File Type"
        className="new-brand-modal pdf-view-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <div className="modal-header-pdf-view">
          <button onClick={() => setViewDetail(false)} className="close-modal">
            <RxCross1 />
          </button>
        </div>
        <div ref={detailRef} className="pdf-viewer-container">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={selectedFile}
              defaultScale={1}
              style={{ height: "400px" }}
            />
          </Worker>
        </div>
      </Modal>

      <Modal
        isOpen={fileTypeModalIsOpen}
        onRequestClose={() => setFileTypeModalIsOpen(false)}
        contentLabel="Select File Type"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Select File Type</h2>
        <p>
          Please select the appropriate file type (design or clinical) before
          attempting to upload.
        </p>
        <button
          className="cancel-button"
          onClick={() => setFileTypeModalIsOpen(false)}
        >
          OK
        </button>
      </Modal>

      {/* Progress modal */}
      <Modal
        isOpen={progressModalIsOpen}
        contentLabel="Progress"
        className="new-brand-modal progress-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <div className="modal-logo">
          <img src="/solstice.png" alt="logo" />{" "}
        </div>
        <div
          className="close-btn"
          onClick={() => setProgressModalIsOpen(false)}
        >
          {/* Span is for icon  */}
          <span></span>
        </div>
        <p>Please wait while files are being Uploaded</p>
        <div className="progress-container">
          <img src="/file-icon.png" alt="icon-file" />
          <div className="progress-main-container">
            <div className="progress-content">
              <div
                className="progress"
                style={{ width: `${Number(progress).toFixed(0)}%` }}
              >
                <span className="progress-icon">
                  <FaChevronRight />
                </span>
              </div>
            </div>
            <div className="progress-status">
              <span className="upload-status">
                {Number(progress).toFixed(0)}% Uploaded
              </span>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete confirm modal */}
      <DeleteModal
        isOpen={deleteModalIsOpen}
        onConfirm={confirmDelete}
        onClose={() => setDeleteModalIsOpen(false)}
        isLoading={eventLoading.delete}
      />
    </div>
  );
};

export default ExistingBrand;
