import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
} from "react";
import Modal from "react-modal";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Header from "./Header";
import { FaCheck, FaChevronLeft, FaEdit, FaTrashAlt } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import "./PreMLRReview.css";
import WebViewer from "@pdftron/webviewer";
import {
  blobToBase64,
  debounce,
  updateAnnotationsInBatches,
} from "./utils/helpers";
import DocumentViewer from "./components/DocumentViewer";
import { saveAs } from "file-saver"; // Importing file-saver
import { Select } from "@chakra-ui/react";
import { File as FileIcon } from "./assets/icons/File";
import Calendar from "./assets/icons/Calendar";
import Edit from "./assets/icons/Edit";
import Action from "./assets/icons/Action";
import Eye from "./assets/icons/Eye";
import DeleteModal from "./components/DeleteModal";
import { RxCross1, RxCross2 } from "react-icons/rx";
import TableLoader from "./components/TableLoader/TableLoader";
import SkeletonLoader from "./components/SkeletonLoader/SkeletonLoader";
import { useNavbarContext } from "./context/NavbarContext";
import LoaderSpin from "./components/LoaderSpin/LoaderSpin";
import AnnotationReasoningDetailModal from "./components/AnnotationReasoningDetailModal";
import { ConfirmModal } from "./components/ConfirmModal/ConfirmModal";
import axios from "axios";
import { useStore } from "./context/GlobalContext";

Modal.setAppElement("#root");

const defaultValues = {
  loading: {
    documentName: false,
    table: false,
    detail: false,
    main: false,
  },
  eventLoading: {
    detail: false,
    main: false,
  },
  displayViewer: {
    detail: false,
    main: false,
  },
};
const DocumentHistory = () => {
  const { documentId } = useParams();
  const [versions, setVersions] = useState([]);
  const [comments, setComments] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [rowStatus, setRowStatus] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalIsOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [clinicalInfoModalIsOpen, setClinicalInfoModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [loading, setLoading] = useState(defaultValues.loading);
  const [confirmModal, setConfirmModal] = useState(false);

  const [displayViewer, setDisplayViewer] = useState(
    defaultValues.eventLoading
  );
  const [eventLoading, setEventLoading] = useState(defaultValues.eventLoading);
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const navigate = useNavigate();
  const [viewerError, setViewerError] = useState(null);
  const [viewVersion, setViewVersion] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [apryseInstance, setApryseInstance] = useState(null);
  const [annotationCustomData, setAnnotationCustomData] = useState("");
  const [documentName, setDocumentName] = useState("");

  const { setConfirmation } = useStore();

  const { isOpen } = useNavbarContext();

  const documentViewerRef = useRef();
  const resetState = useCallback(() => {
    setReviewData(null);
    setApryseInstance(null);
    setViewVersion(null);
    setViewerError(null);
    setConfirmation(false);
    setSelectedVersion(null);
    setEventLoading(defaultValues.eventLoading);
    setDisplayViewer(defaultValues.eventLoading);
    setLoading(defaultValues.eventLoading);
  }, []);

  useImperativeHandle(
    documentViewerRef,
    () => {
      return {
        apryseInstance,
        reviewData,
        resetState,
      };
    },
    [apryseInstance, resetState, reviewData]
  );

  const viewerRef = useRef(null);
  const viewerDiv = useRef(null);
  const documentViewerListenerRef = useRef();
  const detailViewer = useRef(null);

  const filteredDocuments = versions?.length
    ? versions.filter((doc) => {
        const searchValue = searchQuery?.toLowerCase() || "";
        return (
          doc?.version?.toString().toLowerCase().includes(searchValue) ||
          doc?.reviewed_by?.toLowerCase().includes(searchValue)
        );
      })
    : [];

  const fetchDocumentName = useCallback(async () => {
    if (isAuthenticated) {
      setLoading((pre) => ({ ...pre, documentName: true }));
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/document?documentId=${documentId}&teamId=${roles}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setDocumentName(data.name);
        setLoading((pre) => ({ ...pre, documentName: false }));
      } catch (error) {
        console.error("Error fetching document name:", error);
        setLoading((pre) => ({ ...pre, documentName: false }));
      }
    }
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims, documentId]);

  const fetchVersions = useCallback(async () => {
    if (isAuthenticated) {
      setLoading((pre) => ({ ...pre, table: true }));
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/document-versions?documentId=${documentId}&teamId=${roles}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setVersions(data.versions);
        setComments(data.comments);
        setLoading((pre) => ({ ...pre, table: false }));
      } catch (error) {
        console.error("Error fetching document versions:", error);
        setLoading((pre) => ({ ...pre, table: false }));
      }
    }
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims, documentId]);

  useEffect(() => {
    fetchDocumentName();
    fetchVersions();
  }, [fetchDocumentName, fetchVersions]);

  const handleRowStatus = (index, status) => {
    setRowStatus((prevRowStatus) => ({
      ...prevRowStatus,
      [index]: status,
    }));
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
    setRowStatus({});
  };

  const handleSave = () => {
    setModalIsOpen(true);
  };

  const handleModalSave = async () => {
    const reviewData = documentViewerRef.current?.reviewData;

    if (reviewData) {
      setEventLoading((pre) => ({ ...pre, save: true }));
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];
        const teamId = Array.isArray(roles) ? roles[0] : roles;

        if (!documentViewerRef.current.apryseInstance) {
          throw new Error("WebViewer is not initialized");
        }

        const { apryseInstance } = documentViewerRef.current;
        const { documentViewer, annotationManager } = apryseInstance.Core;

        if (!documentViewer.getDocument()) {
          throw new Error("No document is loaded");
        }

        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations();

        const data = await doc.getFileData({
          xfdfString,
        });
        const blob = new Blob([data], { type: "application/pdf" });
        const fileObject = new File([blob], "annotated_document.pdf", {
          type: "application/pdf",
        });

        const formData = new FormData();
        formData.append("file", fileObject);
        formData.append("document_id", documentId);
        formData.append("version", versions.length + 1);
        formData.append("review_date", new Date().toISOString());
        formData.append("reviewed_by", user.email);
        formData.append("comments", JSON.stringify(""));

        const response = await axios(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/document-versions`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: formData,
            keepalive: true,
          }
        );

        const versionData = response.data;
        const versionId = versionData.id;

        await axios(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/version-comments`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify({
              version_id: versionId,
              comments: "",
            }),
            keepalive: true,
          }
        );

        fetchVersions();
        documentViewerRef.current.resetState();
        setSuccessModalIsOpen(true);
        handleModalClose();
      } catch (error) {
        console.error("Error saving document version and comments:", error);
      } finally {
        setEventLoading((pre) => ({ ...pre, save: false }));
      }
    }
  };

  const handleDetail = (event) => {
    const { annotationManager } = viewerRef.current.Core;

    const button = event.target;

    // Retrieve the annotation ID stored in the data attribute
    const annotationId = button.getAttribute("data-annotation-id");

    if (annotationId) {
      // Get the annotation by its ID
      const annotation = annotationManager.getAnnotationById(annotationId);

      if (annotation) {
        // Get the contents of the annotation
        const annotationContents = annotation.getCustomData("customData");

        // Display or log the annotation contents
        console.log("Annotation Contents:", annotationContents);
        setAnnotationCustomData(annotationContents);
        setDetailModalIsOpen(true);
      } else {
        console.error("Annotation not found for ID:", annotationId);
      }
    } else {
      console.error("No annotation ID found for the clicked button.");
    }
  };

  const createWebViewerInstance = async () => {
    viewerDiv.current.innerHTML = "";

    if (viewerRef.current) {
      await viewerRef.current?.UI?.dispose();

      if (documentViewerListenerRef.current) {
        viewerRef.current.Core.documentViewer.removeEventListener(
          "documentLoaded",
          documentViewerListenerRef.current.documentLoaded
        );
        viewerRef.current.Core.documentViewer.removeEventListener(
          "documentLoadFailure",
          documentViewerListenerRef.current.documentLoadFailure
        );
      }
    }

    localStorage.removeItem("persist:search-default");
    localStorage.removeItem("persist:viewer-default");

    const instance = await WebViewer(
      {
        path: "/lib",
        fullAPI: true,
        licenseKey: process.env.REACT_APP_APRYSE_KEY,
        css: "/index.css",
      },
      viewerDiv.current
    );

    viewerRef.current = instance;
    setApryseInstance(instance);

    const { documentViewer, annotationManager } = instance.Core;
    const iframeDoc = instance.UI.iframeWindow.document;

    instance.UI.openElements(["notesPanel"]); // Opens the left sidebar panel by default
    const Feature = instance.UI.Feature;
    instance.UI.disableFeatures([Feature.LocalStorage, Feature.PageNavigation]);
    instance.UI.setAnnotationContentOverlayHandler((annotation) => {
      // remove annotation pop-up
      return null;
    });
    instance.UI.disableElements([
      "notesPanelHeader",
      "toolbarGroup-Shapes",
      "toolbarGroup-Insert",
      "toolbarGroup-Edit",
      "toolbarGroup-FillAndSign",
      "toolbarGroup-Forms",
      "annotationPopup",
    ]);

    annotationManager.setCurrentUser(user?.nickname);

    const addLoading = (currentPage) => {
      const prePage = currentPage > 1 ? currentPage - 1 : currentPage;
      if (prePage !== currentPage) {
        const app = iframeDoc.getElementById("app");
        const loaderContainer = document.createElement("div");
        const loaderDiv = document.createElement("span");
        loaderContainer.classList.add("loading-next-page");
        loaderContainer.append(loaderDiv);
        const contentContainer = iframeDoc.querySelector(".App");
        app.insertBefore(loaderContainer, contentContainer);
        setTimeout(() => {
          app.removeChild(loaderContainer);
        }, 2000);
      }
    };

    documentViewerListenerRef.current = {
      documentLoaded: () => {
        const pageNavOverlay = iframeDoc.querySelector(".PageNavOverlay");
        setViewerError(false);
        if (pageNavOverlay) {
          const preButton = iframeDoc.querySelector(
            "[aria-label='Previous page']"
          );
          const nextButton = iframeDoc.querySelector(
            "[aria-label='Next page']"
          );

          preButton.addEventListener("click", (e) => {
            const currentPage = documentViewer.getCurrentPage();
            if (currentPage > 1) {
              addLoading(currentPage);

              instance.Core.documentViewer.setCurrentPage(currentPage - 1);
            }
          });

          nextButton.addEventListener("click", (e) => {
            e.preventDefault();
            const currentPage = documentViewer.getCurrentPage();
            const totalPages = documentViewer.getPageCount(); // Retrieve total page count
            if (currentPage < totalPages) {
              const updatedPageNumber = currentPage + 1;
              addLoading(updatedPageNumber);
              instance.Core.documentViewer.setCurrentPage(currentPage, true);
            }
          });
        }
        console.log("Document loaded successfully");
        instance.UI.setLayoutMode(instance.UI.LayoutMode.Single);
        documentViewer.setFitMode(documentViewer.FitMode.FitWidth);

        setDisplayViewer((pre) => ({ ...pre, main: true, detail: false }));
        setLoading((pre) => ({ ...pre, main: false, detail: false }));
      },
      documentLoadFailure: (error) => {
        console.error("Failed to load document:", error);
        setViewerError("Failed to load document");
        setDisplayViewer((pre) => ({ ...pre, main: false, detail: false }));
        setLoading((pre) => ({ ...pre, main: false, detail: false }));
      },
    };

    documentViewer.addEventListener(
      "documentLoaded",
      documentViewerListenerRef.current.documentLoaded
    );

    documentViewer.addEventListener(
      "documentLoadFailure",
      documentViewerListenerRef.current.documentLoadFailure
    );

    // Debounced function to handle page changes
    function onPageChange() {
      const currentPage = documentViewer.getCurrentPage();
      const annotations = annotationManager.getAnnotationsList();
      // Batch render annotations
      updateAnnotationsInBatches(
        annotations,
        currentPage,
        50,
        annotationManager
      );
    }

    documentViewer.addEventListener(
      "pageNumberUpdated",
      debounce(onPageChange, 50) // Debounced page change handler
    );

    // Initial rendering of annotations
    annotationManager.addEventListener(
      "annotationChanged",
      debounce((annotations) => {
        const currentPage = documentViewer.getCurrentPage();
        updateAnnotationsInBatches(
          annotations,
          currentPage,
          50,
          annotationManager
        );
      }, 50)
    );

    documentViewer.addEventListener("annotationsLoaded", () => {
      const currentPage = documentViewer.getCurrentPage();
      const annotations = annotationManager.getAnnotationsList();
      updateAnnotationsInBatches(
        annotations,
        currentPage,
        50,
        annotationManager
      );
    });

    instance.UI.addEventListener(
      instance.UI.Events.VISIBILITY_CHANGED,
      async () => {
        const isOpen = await instance.UI.isElementOpen("notesPanel");
        if (isOpen) {
          const notesPanel = iframeDoc.querySelector(".NotesPanel");
          if (notesPanel) {
            const saveButton = iframeDoc.querySelector("#saveButton");
            /**Check if save button exist in iframe if not exist then create */
            if (!saveButton) {
              notesPanel.classList.add("custom-notePanel");
              const button = document.createElement("button");
              button.classList.add("save-button-custom");
              button.onclick = handleSave;
              button.textContent = "Save";
              button.id = "saveButton";
              notesPanel.appendChild(button);
            }
          }
        }
      }
    );

    instance.UI.addEventListener(
      instance.UI.Events.VISIBILITY_CHANGED,
      async (event) => {
        const isOpen = await instance.UI.isElementOpen("notesPanel");
        if (isOpen) {
          const notesPanels = iframeDoc.querySelectorAll(".Note");

          if (notesPanels.length) {
            const annotations = annotationManager.getAnnotationsList();

            for (let i = 0; i < notesPanels.length; i++) {
              const element = notesPanels[i];

              const saveButton = element.querySelector("#modal-see-reasons");

              if (!saveButton) {
                element.classList.add("custom-notePanel");

                const button = document.createElement("button");
                button.classList.add("see-button-custom");
                button.classList.add("see-reasons");
                button.onclick = handleDetail;
                button.textContent = "See Reasoning";
                button.id = "modal-see-reasons";

                // Get the annotation ID associated with this note panel
                const annotationId = annotations[i]?.Id;
                if (annotationId) {
                  // Store the annotation ID as a data attribute on the button
                  button.setAttribute("data-annotation-id", annotationId);
                }

                const dateTimeDiv = element.querySelector(".NoteContent");

                if (dateTimeDiv) {
                  dateTimeDiv.parentNode.appendChild(button);
                }
              }
            }
          }
        }
      }
    );

    console.log("Setting isViewerReady to true");

    return instance;
  };

  /**Detail popup-viewer */
  const initializeDetailViewer = useCallback(async () => {
    localStorage.removeItem("persist:search-default");
    localStorage.removeItem("persist:viewer-default");
    if (detailViewer.current?.UI) {
      await detailViewer.current?.UI?.dispose();
    }

    const instance = await WebViewer(
      {
        path: "/lib",
        fullAPI: false,
        licenseKey: process.env.REACT_APP_APRYSE_KEY,
        css: "/index.css",
      },
      detailViewer.current
    );
    detailViewer.current = instance;

    const { UI, Core } = instance;
    const { documentViewer } = Core;
    const { Feature } = UI;

    instance.UI.disableFeatures([Feature.LocalStorage]);

    documentViewer.addEventListener("documentLoaded", () => {
      setLoading((pre) => ({ ...pre, detail: false, main: false }));
      setDisplayViewer((pre) => ({ ...pre, detail: true, main: false }));
    });

    documentViewer.addEventListener("documentLoadFailure", () => {
      setLoading((pre) => ({ ...pre, detail: false, main: false }));
      setDisplayViewer((pre) => ({ ...pre, detail: true, main: false }));
    });
    UI.disableElements([
      "toolbarGroup-Annotate",
      "toolbarGroup-Insert",
      "toolbarGroup-Shapes",
      "toolbarGroup-Edit",
      "toolbarGroup-FillAndSign",
      "toolbarGroup-Measure",
      "toolbarGroup-Form",
      "toolbarGroup-View",
      "toolbarGroup-Standard",
      "ribbons",
      "toggleNotesButton",
      "viewControlsButton",
      "searchButton",
      "panToolButton",
      "pageNavOverlay",
      "menuButton",
      "selectToolButton",
      "leftPanelButton",
      "zoomOverlayButton",
      "moreButton",
      "toolsOverlay",
      "textPopup",
      "annotationPopup",
    ]);
    UI.setAnnotationContentOverlayHandler(() => null);
    UI.disableFeatures([UI.Feature.ThumbnailsPanel, UI.Feature.NotesPanel]);
    const iframeDoc = instance.UI.iframeWindow.document;
    const zoomOverlay = iframeDoc.querySelector("#app");
    zoomOverlay.classList.add("no-header-toolbar");

    return instance;
  }, []);

  const handleVersionClick = async (version, action) => {
    if (action === "edit") {
      setSelectedVersion(version);
      setViewVersion(null);
    } else {
      setViewVersion(version);

      setDetailsModalIsOpen(!detailsModalOpen);
    }

    setLoading((pre) => ({ ...pre, detail: true, main: true }));
    setViewerError(null);
    setDisplayViewer((pre) => ({ ...pre, detail: false, main: false }));
    try {
      if (action === "edit") {
        await createWebViewerInstance();
      }
      console.log("Loading document...");

      const url = `${
        process.env.REACT_APP_BACKEND_APP_API_URL
      }/api/file-versions?fileName=${encodeURIComponent(version.file_path)}`;
      const token = await getAccessTokenSilently();
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await res.blob();
      const data = await blobToBase64(blob);
      if (action === "edit") {
        const { documentViewer } = viewerRef.current.Core;
        await documentViewer.loadDocument(data);
        setReviewData(data);
      } else {
        await initializeDetailViewer();
        const { documentViewer } = detailViewer.current.Core;
        await documentViewer.loadDocument(data);
      }
    } catch (error) {
      setDisplayViewer((pre) => ({ ...pre, main: false, detail: false }));
      setLoading((pre) => ({ ...pre, main: false, detail: false }));
      console.error("Error loading document:", error);
      setViewerError("Error loading document");
    }
  };
  // Function to export comments to CSV
  const exportCommentsToCSV = (comments) => {
    const csvContent = [
      ["Problem Text", "Comment", "Supporting Source", "Suggested Edit"],
      ...comments.map((comment) => [
        comment.problem_text,
        comment.comment,
        comment.supporting_source,
        comment.suggested_edit,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "comments.csv");
  };
  const fetchDocumentVersions = async () => {
    try {
      const token = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      const decodedToken = jwtDecode(idToken.__raw);
      const roles = decodedToken["https://solsticehealth.co/roles"];
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/document-versions?documentId=${documentId}&teamId=${roles}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch document versions');
      }
      const data = await response.json();
      setVersions(data.versions);
    } catch (error) {
      console.error('Error fetching document versions:', error);
    }
  };

  const confirmDelete = async () => {
    if (documentToDelete) {
      setEventLoading((pre) => ({ ...pre, delete: true }));
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];
        console.log(
          `Deleting version with id: ${documentToDelete.id}, teamId: ${roles}`
        );
  
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/document-versions/${documentToDelete.id}?teamId=${roles}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        setDeleteModalIsOpen(false);
        setDocumentToDelete(null);
        fetchVersions();
      } catch (error) {
        console.error("Error deleting document version:", error);
      } finally {
        setEventLoading((pre) => ({ ...pre, delete: false }));
      }
    }
  };

  return (
    <div
      className={`content_container ${selectedVersion ? "editor-open" : ""} ${
        !isOpen ? "container-expend" : ""
      }`}
    >
      <Header />
      <Navbar />
      <div className="container version-container">
        <div className="table-section ">
          {displayViewer.main ? (
            <>
              <button
                type="button"
                className="gradient-btn back-btn"
                onClick={() => {
                  setConfirmModal((pre) => !pre);
                }}
              >
                <span>
                  <FaChevronLeft />
                </span>
                Back
              </button>
            </>
          ) : null}
          {!selectedVersion && (
            <>
              <div className="table-header-content">
                <h2>
                  {!loading.documentName ? (
                    documentName
                  ) : (
                    <SkeletonLoader
                      style={{ width: "250px", height: "20px" }}
                    />
                  )}
                </h2>
                <div className="table-filters">
                  <div className="dropdown">
                    <label>Sort by :</label>
                    <Select placeholder="Select option">
                      <option value="option1">Latest</option>
                      <option value="option2">Option 2</option>
                      <option value="option3">Option 3</option>
                    </Select>
                  </div>
                  <input
                    type="text"
                    placeholder="Search documents"
                    value={searchQuery}
                    onChange={({ target: { value } }) => setSearchQuery(value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="material-table">
                  <thead>
                    <tr>
                      <th>
                        <FileIcon />
                        Document Name
                      </th>
                      <th>
                        <Calendar />
                        Date Created
                      </th>
                      <th>
                        <Calendar />
                        Review Date
                      </th>
                      <th>
                        <Edit />
                        Reviewed By
                      </th>
                      <th>
                        <Action />
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading.table ? (
                      filteredDocuments.map((version, index) => (
                        <tr key={index}>
                          <td>
                            {version.document_name} {""} v{version.version}
                          </td>
                          <td>
                            {new Date(
                              version.created_date
                            ).toLocaleDateString()}
                          </td>
                          <td>
                            {new Date(version.review_date).toLocaleDateString()}
                          </td>
                          <td>{version.reviewed_by}</td>
                          <td>
                            <div className="action-buttons">
                              <button
                                className="delete-button"
                                onClick={(e) => {
                                  setDeleteModalIsOpen(true);
                                  setDocumentToDelete(version);
                                  e.stopPropagation();
                                }}
                              >
                                <FaTrashAlt />
                              </button>
                              <button
                                className="view-button"
                                onClick={(e) => {
                                  handleVersionClick(version);
                                }}
                              >
                                <Eye />
                              </button>
                              <button
                                className="edit-button"
                                onClick={(e) => {
                                  handleVersionClick(version, "edit");
                                }}
                              >
                                <Edit color="#000" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <TableLoader />
                    )}
                  </tbody>
                </table>
              </div>

              <div className="document-viewer">
                <h2 style={{ marginTop: "10px" }}>
                  Review New Version of Document
                </h2>
                <DocumentViewer ref={documentViewerRef} onSave={handleSave} />
              </div>
            </>
          )}
        </div>

        {selectedVersion && (
          <div className="version-details">
            {loading.main ? (
              <div className="loading-indicator">
                <SkeletonLoader
                  style={{ height: "36px", marginBottom: "3px" }}
                />
                <div style={{ display: "flex" }}>
                  <SkeletonLoader
                    style={{
                      height: "calc(-244px + 100vh)",
                      marginRight: "3px",
                      width: "calc(100%-400px)",
                    }}
                  />
                  <SkeletonLoader
                    style={{
                      height: "calc(-244px + 100vh)",
                      width: "400px",
                    }}
                  />
                </div>
              </div>
            ) : viewerError ? (
              <div className="error-message">{viewerError}</div>
            ) : null}
          </div>
        )}

        <div
          id="webViewer"
          className="webViewer"
          ref={viewerDiv}
          style={{
            width:
              (!selectedVersion || loading.main || viewerError) &&
              !displayViewer.main
                ? "0px"
                : "100%",
            height:
              (!selectedVersion || loading.main || viewerError) &&
              !displayViewer.main
                ? "0px"
                : "calc(100vh - 162px)",
          }}
        />

        {/* Confirm save modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleModalClose}
          contentLabel="Upload New Version"
          className="new-brand-modal"
          overlayClassName="new-brand-modal-overlay"
        >
          <div className="modal-logo">
            <img src="/solstice.png" alt="logo" />{" "}
          </div>
          <h2>Save Document</h2>
          <div className="modal-buttons">
            <button className="save-button" onClick={handleModalSave}>
              {eventLoading.save ? (
                <LoaderSpin style={{ height: "30px", width: "30px" }} />
              ) : (
                <>
                  <FaCheck />
                  Save
                </>
              )}
            </button>
            <button className="cancel-button" onClick={handleModalClose}>
              <RxCross2 />
              Cancel
            </button>
          </div>
        </Modal>

        {/* view detail modal  */}
        <Modal
          isOpen={detailsModalOpen}
          onRequestClose={setDetailsModalIsOpen}
          contentLabel="Detail model"
          className="new-brand-modal pdf-view-modal"
          overlayClassName="new-brand-modal-overlay"
        >
          <div className="modal-header-pdf-view">
            <button
              disabled={loading.detail}
              onClick={(e) => {
                setDetailsModalIsOpen(false);
                handleVersionClick(viewVersion, "edit");
              }}
              className="pdf-edit"
            >
              <FaEdit />
            </button>
            <button
              disabled={loading.detail}
              onClick={(e) => {
                setDeleteModalIsOpen(true);
                setDocumentToDelete(viewVersion);
              }}
              className="pdf-delete"
            >
              <FaTrashAlt />
            </button>
            <button
              onClick={() => {
                setViewVersion(null);
                setDetailsModalIsOpen(false);
              }}
              className="close-modal"
            >
              <RxCross1 />
            </button>
          </div>
          <div className="modal-content">
            {loading.detail ? (
              <SkeletonLoader style={{ height: "500px" }} />
            ) : null}
            <div
              ref={detailViewer}
              style={{
                height: !displayViewer.detail ? "0px" : "100%",
                width: !displayViewer.detail ? "0px" : "100%",
              }}
            />
          </div>
        </Modal>

        <Modal
          isOpen={successModalIsOpen}
          onRequestClose={() => setSuccessModalIsOpen(false)}
          contentLabel="Success"
          className="new-brand-modal"
          overlayClassName="new-brand-modal-overlay"
        >
          <h2>Success</h2>
          <p>Document and comments saved successfully!</p>
          <div className="modal-buttons">
            <button
              className="save-button"
              onClick={() => setSuccessModalIsOpen(false)}
            >
              OK
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={clinicalInfoModalIsOpen}
          onRequestClose={() => setClinicalInfoModalIsOpen(false)}
          contentLabel="Upload Clinical Information"
          className="new-brand-modal"
          overlayClassName="new-brand-modal-overlay"
        >
          <h2>Upload Clinical Information</h2>
          <p>
            Please upload clinical information for the selected brand before
            proceeding.
          </p>
          <button onClick={() => setClinicalInfoModalIsOpen(false)}>OK</button>
        </Modal>

        {/* Delete Modal */}
        <DeleteModal
          isOpen={deleteModalIsOpen}
          onConfirm={confirmDelete}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={eventLoading.delete}
        />

        <AnnotationReasoningDetailModal
          isOpen={detailModalIsOpen}
          onClose={() => setDetailModalIsOpen(false)}
          data={annotationCustomData}
        />

        {/* Back confirm modal */}
        <ConfirmModal
          open={confirmModal}
          setOpen={setConfirmModal}
          onConfirm={() => {
            setConfirmModal((pre) => !pre);
            documentViewerRef.current?.resetState();
          }}
        />
      </div>
    </div>
  );
};

export default DocumentHistory;
