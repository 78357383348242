import React from "react";
import Modal from "react-modal";
import { FaChevronRight } from "react-icons/fa";
import "./ProgressModal.css";
import { getFormattedTime } from "../../utils/helpers";
export default function ProgressModal({
  isOpen = false,
  progress = 0,
  fileSize = 0,
  estimatedTime = 0,
  onClose = () => {},
  progressDetail = { currentStep: "", currentMessage: "" },
}) {
  const { currentMessage, currentStep } = progressDetail;
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Confirm Delete"
      className="new-brand-modal progress-modal"
      overlayClassName="new-brand-modal-overlay"
    >
      <div className="modal-logo">
        <img src="/solstice.png" alt="logo" />{" "}
      </div>

      <div className="close-btn" onClick={onClose}>
        {/* Span is for icon  */}
        <span></span>
      </div>
      <p>Please wait while files are being processed</p>
      <div className="progress-container">
        <img src="/file-icon.png" alt="icon-file" />
        <div className="progress-main-container">
          {currentStep && <p className="current-step">{currentStep}</p>}
          <div className="progress-content">
            <div
              className="progress"
              style={{ width: `${progress.toFixed(0)}%` }}
            >
              <span className="progress-icon">
                <FaChevronRight />
              </span>
            </div>
          </div>
          <div className="progress-status">
            <span className="upload-status">
              {progress.toFixed(0)}% Uploaded
            </span>
            <span className="file-size">{fileSize}</span>
          </div>
          {currentMessage && (
            <p className="current-message">{currentMessage}</p>
          )}
        </div>
      </div>
      <p>{getFormattedTime(estimatedTime)} min left to upload File</p>
    </Modal>
  );
}
