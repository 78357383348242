import React from 'react';
import './LoginPage.css';
import { useAuth0 } from '@auth0/auth0-react';


const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src='/solstice.png' alt="Solstice Logo" className="logo" />
        <div className="company-name">Solstice Health</div>
        <div className="tagline">Your AI Life Sciences Marketing Engine</div>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default LoginPage;