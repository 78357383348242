import React, { createContext, useState, useContext } from 'react';

const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
  const [uploads, setUploads] = useState({});
  const [clinicalUploadsInProgress, setClinicalUploadsInProgress] = useState(0);

  const addUpload = (id, fileName, fileType) => {
    setUploads(prev => ({ ...prev, [id]: { fileName, fileType } }));
    if (fileType === 'clinical') {
      setClinicalUploadsInProgress(prev => prev + 1);
    }
  };

  const removeUpload = (id) => {
    setUploads(prev => {
      const newUploads = { ...prev };
      if (newUploads[id] && newUploads[id].fileType === 'clinical') {
        setClinicalUploadsInProgress(prev => Math.max(0, prev - 1));
      }
      delete newUploads[id];
      return newUploads;
    });
  };

  return (
    <UploadContext.Provider value={{ uploads, addUpload, removeUpload, clinicalUploadsInProgress }}>
      {children}
    </UploadContext.Provider>
  );
};

export const useUpload = () => useContext(UploadContext);