import React from "react";

const Edit = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M14.9475 12.689V15.7884C14.9475 16.1994 14.7843 16.5936 14.4936 16.8842C14.203 17.1749 13.8088 17.3382 13.3978 17.3382H2.54973C2.13871 17.3382 1.74453 17.1749 1.4539 16.8842C1.16327 16.5936 1 16.1994 1 15.7884V4.94035C1 4.52934 1.16327 4.13516 1.4539 3.84453C1.74453 3.5539 2.13871 3.39063 2.54973 3.39062H5.64918"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.13605 12.5339L16.4972 5.09524L13.2428 1.84082L5.88163 9.20202L5.64917 12.6889L9.13605 12.5339Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Edit;
