import React from "react";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Modal from "react-modal";

export const ConfirmModal = ({
  open = false,
  setOpen,
  onConfirm,
  onCancel,
  headLine = "Are you sure you want to leave? Please save your changes.",
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen && setOpen(!open)}
      contentLabel="Save Document"
      className="new-brand-modal"
      overlayClassName="new-brand-modal-overlay"
    >
      <div className="modal-logo">
        <img src="/solstice.png" alt="logo" />{" "}
      </div>
      <h2>{headLine}</h2>
      <div className="modal-buttons">
        <button
          onClick={onConfirm ? onConfirm : undefined}
          className="save-button"
        >
          <FaCheck />
          Confirm
        </button>
        <button
          className="cancel-button"
          onClick={onCancel ? onCancel() : () => setOpen(!open)}
        >
          <RxCross2 /> Cancel
        </button>
      </div>
    </Modal>
  );
};
