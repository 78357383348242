import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import Bell from "../../assets/icons/Bell";
import "./NotificationModal.css";
import { useStore } from "../../context/GlobalContext";
import { bytesToSize, getFormattedTime } from "../../utils/helpers";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

export default function NotificationModal() {
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    progress,
    uploadedFile,
    estimatedTime = 0,
    progressStatus,
    setProgressModalIsOpen,
    setIsNotificationClear,
    isNotificationClear,
    progressModalIsOpen,
  } = useStore();

  const isFailed =
    progressStatus.guidelines === "Failed" &&
    progressStatus.annotations === "Failed";

  const fileSize = uploadedFile ? bytesToSize(uploadedFile?.size) : 0;

  const fileName = uploadedFile ? uploadedFile?.name ?? "" : "";

  const handleModalOpen = () => {
    if (isFailed) return;
    setProgressModalIsOpen(true);
  };

  const clearAll = () => {
    setConfirmModal(false);
    setIsNotificationClear(true);
  };
  useEffect(() => {
    if (progressModalIsOpen) {
      setIsNotificationClear(false);
    }
  }, [progressModalIsOpen, setIsNotificationClear]);
  return (
    <Menu className="notification-menu">
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={typeof isOpen === "boolean" ? isOpen : false}
            as={IconButton}
            aria-label="Options"
            icon={
              <div className="bell-icon">
                <Bell />
                {!isNotificationClear ? (
                  <span className="badge">{progress > 0 ? 1 : 0}</span>
                ) : null}
              </div>
            }
            variant="outline"
          ></MenuButton>

          <MenuList>
            <Box bg="white" w="100%" color="black">
              <Box>
                <Flex
                  className="header-notification"
                  justifyContent="space-between"
                >
                  <Text>Notifications</Text>

                  {progress > 0 && !isNotificationClear && (
                    <Button
                      onClick={() => setConfirmModal(!confirmModal)}
                      as={Link}
                    >
                      clear all
                    </Button>
                  )}
                </Flex>
                <VStack className="notifcation-wrap" align="stretch" gap={0}>
                  {uploadedFile && progress > 0 && !isNotificationClear && (
                    <Box
                      onClick={handleModalOpen}
                      borderBottom="1px solid rgba(0,0,0,0.10)"
                      paddingBottom="30px"
                      display="flex"
                      justifyContent="spaceBetween"
                      cursor={"pointer"}
                    >
                      <Box display="flex" className="pdf-detail">
                        <img src="/pdf-icon.png" alt="pdf-logo" width="32" />
                        <Box>
                          <Text className="file-name">{fileName}</Text>
                          <Text className="file-data">{fileSize}</Text>
                          <Progress
                            value={progress?.toFixed(0)}
                            className="progress-bar"
                          />
                        </Box>
                      </Box>
                      <Box className="pdf-status">
                        <Text className="time-left">
                          {getFormattedTime(estimatedTime)} min left
                        </Text>
                        <Text className="status-text">
                          {!isFailed ? (
                            <>{progress?.toFixed(0)}% Completed</>
                          ) : (
                            <Text className="status-text">
                              <img src="/failed.png" alt="status" /> Failed to
                              upload
                            </Text>
                          )}
                        </Text>
                      </Box>
                    </Box>
                  )}
                  {/* <Box
                    borderBottom="1px solid rgba(0,0,0,0.10)"
                    paddingBottom="30px"
                    paddingTop="20px"
                    display="flex"
                    justifyContent="spaceBetween"
                  >
                    <Box display="flex" className="pdf-detail">
                      <img src="/pdf-icon.png" alt="pdf-logo" width="32" />
                      <Box>
                        <Text className="file-name">File name example.pdf</Text>
                        <Text className="file-data">200GB</Text>
                      </Box>
                    </Box>
                    <Box className="pdf-status ready-to-review">
                      <Text className="status-text ">
                        <Eye /> 40% Completed
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    borderBottom="1px solid rgba(0,0,0,0.10)"
                    paddingBottom="30px"
                    paddingTop="20px"
                    display="flex"
                    justifyContent="spaceBetween"
                  >
                    <Box display="flex" className="pdf-detail">
                      <img src="/pdf-icon.png" alt="pdf-logo" width="32" />
                      <Box>
                        <Text className="file-name">File name example.pdf</Text>
                        <Text className="file-data">200GB</Text>
                      </Box>
                    </Box>
                    <Box className="pdf-status reviewed">
                      <Text className="status-text">
                        <img src="/reviewed.png" alt="status" /> Reviewed
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    borderBottom="1px solid rgba(0,0,0,0.10)"
                    paddingBottom="30px"
                    paddingTop="20px"
                    display="flex"
                    justifyContent="spaceBetween"
                  >
                    <Box display="flex" className="pdf-detail">
                      <img src="/pdf-icon.png" alt="pdf-logo" width="32" />
                      <Box>
                        <Text className="file-name">File name example.pdf</Text>
                        <Text className="file-data">200GB</Text>
                      </Box>
                    </Box>
                    <Box className="pdf-status failed">
                      <Text className="status-text">
                        <img src="/failed.png" alt="status" /> Failed to upload
                      </Text>
                    </Box>
                  </Box> */}
                </VStack>
              </Box>
            </Box>
            <ConfirmModal
              open={confirmModal}
              setOpen={setConfirmModal}
              onConfirm={clearAll}
              headLine={`Are you sure you want to exit? There is a document currently processing.`}
            />
          </MenuList>
        </>
      )}
    </Menu>
  );
}
