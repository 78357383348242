import React from "react";

const Action = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M4.6035 16.0431C5.30041 15.2952 6.36279 15.3547 6.97471 16.1706L7.83312 17.318C8.52154 18.2273 9.6349 18.2273 10.3233 17.318L11.1817 16.1706C11.7936 15.3547 12.856 15.2952 13.5529 16.0431C15.0657 17.6579 16.2981 17.1225 16.2981 14.8618V5.28346C16.2981 1.85839 15.4992 1 12.2866 1H5.86135C2.64875 1 1.84985 1.85839 1.84985 5.28346V14.8533C1.85835 17.1225 3.09919 17.6494 4.6035 16.0431Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.7467 7.79932H11.4211"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Action;
