import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";

const TableLoader = ({ totalLoaders = 5, totalColumn = 5 }) => {
  return Array.from({ length: totalLoaders }).map((_, index) => (
    <tr key={`.${index}`} className="table-loader">
      {Array.from({ length: totalColumn }).map((_, idx) => (
        <td key={`${index}.${idx}`} className="table-loader-columns">
          <SkeletonLoader className={"table-loader-data"} />
        </td>
      ))}
    </tr>
  ));
};

export default TableLoader;
