import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UploadProvider } from "./UploadContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import LoginPage from "./LoginPage";
import ExistingBrand from "./ExistingBrand";
import PreMLRReview from "./PreMLRReview";
import LoginCallback from "./LoginCallback";
import { BrandProvider } from "./BrandContext";
import BrandRules from "./BrandRules";
import Profile from "./Profile";
import DocumentHistory from "./DocumentHistory";
import "react-toastify/dist/ReactToastify.css";
import ReviewNotification from "./components/ReviewNotification/ReviewNotification";
import Loader from "./components/Loader/Loader";
import ProgressModal from "./components/ProgressModal/ProgressModal";
import { useStore } from "./context/GlobalContext";
import { bytesToSize } from "./utils/helpers";
import ClinicalProcessingNotification from "./components/ClinicalProcessingNotification/ClinicalProcessingNotification";

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

function AuthenticatedApp() {
  const { isAuthenticated, isLoading } = useAuth0();
  const {
    progress,
    uploadedFile,
    estimatedTime,
    progressModalIsOpen,
    setProgressModalIsOpen,
    progressDetail,
  } = useStore();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/PreMLRReview" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/ExistingBrand"
          element={
            isAuthenticated ? <ExistingBrand /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/Brandrules"
          element={isAuthenticated ? <BrandRules /> : <Navigate to="/login" />}
        />
        <Route
          path="/PreMLRReview"
          element={
            isAuthenticated ? <PreMLRReview /> : <Navigate to="/login" />
          }
        />
        <Route path="/callback" element={<LoginCallback />} />
        <Route
          path="/profile"
          element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
        />
        <Route
          path="/document-history/:documentId"
          element={
            isAuthenticated ? <DocumentHistory /> : <Navigate to="/login" />
          }
        />
      </Routes>
      {/* review document Complete notification  */}
      <ReviewNotification />
      <ClinicalProcessingNotification />
      <ProgressModal
        isOpen={progressModalIsOpen}
        progress={progress}
        estimatedTime={estimatedTime}
        fileSize={bytesToSize(uploadedFile?.size)}
        onClose={() => setProgressModalIsOpen(false)}
        progressDetail={progressDetail}
      />
    </>
  );
}

function App() {
  return (
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <UploadProvider>
          <BrandProvider>
            <AuthenticatedApp />
          </BrandProvider>
        </UploadProvider>
      </Auth0Provider>
    </Router>
  );
}

export default App;
