import React from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import { TypeAnimation } from 'react-type-animation';

const AnnotationReasoningDetailModal = ({ isOpen, onClose, data }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Select File Type"
      className="new-brand-modal reasons-modal"
      overlayClassName="new-brand-modal-overlay reasons-modal-overlay"
    >
      <header>
        <span onClick={() => onClose()} className="cross">
          <RxCross2 />
        </span>
      </header>
      <div className="modal-content">
        {Object.keys(data)?.length ? (
          <div className="annotation-texts">
            <div>
              <h2>Enriched Claim</h2>
              <TypeAnimation
                sequence={[
                  data?.text || "",
                  2000,
                  () => { },
                ]}
                speed={70}
                wrapper="p"
                repeat={0}
              />
            </div>
            <div>
              <h2>Reasoning</h2>
              <TypeAnimation
                sequence={[
                  data?.reasoning || "",
                  2000,
                  () => { },
                ]}
                speed={70}
                wrapper="p"
                repeat={0}
              />
            </div>
          </div>
        ) : (
          <h1>No Reasoning Found</h1>
        )}
      </div>
    </Modal>
  );
};

export default AnnotationReasoningDetailModal;
