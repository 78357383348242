import React from "react";

const Eye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
    >
      <path
        d="M11.5 12.0364C13.4836 12.0364 15.0917 10.4283 15.0917 8.4447C15.0917 6.46107 13.4836 4.85303 11.5 4.85303C9.51637 4.85303 7.90833 6.46107 7.90833 8.4447C7.90833 10.4283 9.51637 12.0364 11.5 12.0364Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M21.3029 7.1687C21.7676 7.73295 22 8.01501 22 8.44458C22 8.87414 21.7676 9.15621 21.3029 9.72046C19.6027 11.7847 15.8527 15.6279 11.5 15.6279C7.1472 15.6279 3.39724 11.7847 1.69707 9.72046C1.23236 9.15621 1 8.87414 1 8.44458C1 8.01501 1.23236 7.73295 1.69707 7.1687C3.39724 5.10449 7.1472 1.26123 11.5 1.26123C15.8527 1.26123 19.6027 5.10449 21.3029 7.1687Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Eye;
