import React, { useState } from "react";
import Modal from "react-modal";
import "./NewBrandModal.css";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa";

const NewBrandModal = ({ isOpen, onClose, onSubmit, validationMessage }) => {
  const [sponsor, setSponsor] = useState("");
  const [brandName, setBrandName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(sponsor, brandName);
    setSponsor("");
    setBrandName("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Create New Brand"
      className="new-brand-modal"
      overlayClassName="new-brand-modal-overlay"
    >
      <div className="modal-logo">
        <img src="/solstice.png" alt="logo" />
      </div>
      <h2>Create New Brand</h2>
      {validationMessage && <p className="validation-message">{validationMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="sponsor">Sponsor:</label>
          <input
            type="text"
            id="sponsor"
            value={sponsor}
            onChange={(e) => setSponsor(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="brandName">Brand Name:</label>
          <input
            type="text"
            id="brandName"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            required
          />
        </div>
        <div className="modal-buttons">
          <button type="submit" className="save-button">
            <FaCheck />
            Create
          </button>
          <button className="cancel-button" onClick={onClose}>
            <RxCross2 /> Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default NewBrandModal;