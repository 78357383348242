import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";

const GlobalContext = createContext(null);

const GlobalContextProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [notify, setNotify] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const apryseInstanceRef = useRef();
  const [startTime, setStartTime] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [progressModalIsOpen, setProgressModalIsOpen] = useState(false);
  const [isNotificationClear, setIsNotificationClear] = useState(false);
  const [isAnnotationsCompleted, setIsAnnotationsCompleted] = useState(false);
  const [isGuidelinesCompleted, setIsGuidelinesCompleted] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [progressStatus, setProgressStatus] = useState({
    guidelines: "idle",
    annotations: "idle",
  });
  const [progressDetail, setProgressDetail] = useState({
    currentStep: "",
    currentMessage: "",
  });
  useEffect(() => {
    if (progress > 0 && startTime) {
      const elapsedTime = (new Date() - startTime) / 1000; // elapsed time in seconds
      const progressFraction = progress / 100; // Convert progress percentage to a fraction
      const estimatedTotalTime = elapsedTime / progressFraction; // total estimated time in seconds
      const remainingTime = estimatedTotalTime - elapsedTime; // remaining time in seconds

      setEstimatedTime(Math.max(0, remainingTime)); // ensure remaining time is not negative
    }
  }, [progress, startTime]);

  const startProcess = () => {
    setStartTime(new Date()); // Record start time
    setProgress(0); // Reset progress
  };

  const updateProgress = (newProgress) => {
    setProgress(newProgress);
  };
  const isReviewContentFailed = useMemo(
    () =>
      progressStatus.guidelines === "Failed" &&
      progressStatus.annotations === "Failed",
    [progressStatus.annotations, progressStatus.guidelines]
  );

  const showViewer = useMemo(
    () =>
      (isAnnotationsCompleted &&
        isGuidelinesCompleted &&
        !isReviewContentFailed) ||
      (!isAnnotationsCompleted &&
        isGuidelinesCompleted &&
        progressStatus.annotations === "Failed") ||
      (isAnnotationsCompleted &&
        !isGuidelinesCompleted &&
        progressStatus.guidelines === "Failed"),
    [
      isAnnotationsCompleted,
      isGuidelinesCompleted,
      isReviewContentFailed,
      progressStatus.annotations,
      progressStatus.guidelines,
    ]
  );

  useEffect(() => {
    if (showViewer) {
      setConfirmation(false);
      setProgress(100);
      setProgressModalIsOpen(false);
      setNotify(true);
      setProgressDetail({
        currentStep: "",
        currentMessage: "",
      });
      setTimeout(() => {
        setNotify(false);
        setProgress(0);
      }, 5000);
    }
  }, [showViewer]);

  const resetGlobalStates = useCallback(() => {
    setReviewData(null);
    setIsAnnotationsCompleted(false);
    setIsGuidelinesCompleted(false);
  }, []);

  useEffect(() => {
    if (isReviewContentFailed) {
      resetGlobalStates();
      setUploadedFile(null);
      setProgress(0);
      toast.error("Failed to Review Your Content", { toastId: "12" });
    }
  }, [isReviewContentFailed, resetGlobalStates]);

  return (
    <GlobalContext.Provider
      value={{
        progress,
        uploadedFile,
        setProgress: updateProgress,
        setUploadedFile,
        notify,
        setNotify,
        reviewData,
        setReviewData,
        apryseInstanceRef,
        startProcess,
        estimatedTime,
        setProgressStatus,
        progressStatus,
        setProgressModalIsOpen,
        progressModalIsOpen,
        setIsNotificationClear,
        isNotificationClear,
        setIsAnnotationsCompleted,
        setIsGuidelinesCompleted,
        isAnnotationsCompleted,
        isGuidelinesCompleted,
        showViewer,
        isReviewContentFailed,
        resetGlobalStates,
        setConfirmation,
        confirmation,
        setProgressDetail,
        progressDetail,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;

const useStore = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("You cannot use this hook outside of the context");
  }
  return context;
};
export { GlobalContext, useStore };
