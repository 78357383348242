import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useStore } from '../../context/GlobalContext';
import { useUpload } from '../../UploadContext';

const ReviewNotification = () => {
  const { notify } = useStore();
  const { clinicalUploadsInProgress } = useUpload()
  if (clinicalUploadsInProgress > 0) {
    return
  }
  return (
    <div className={`ready-to-review-popup ${notify ? "active" : ""}`}>
      <div className="content-popup">
        <span>
          <FaCheck />
        </span>
        <p>
          <b>Your document is ready for review</b>
        </p>
        <Link to="/PreMLRReview?redirect-view=true">Return to the document</Link>
      </div>
    </div>
  )
}

export default ReviewNotification