import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TfiMenuAlt } from "react-icons/tfi";
import "./Navbar.css";
import Select, { components } from "react-select";
import { FaPlus, FaTrash } from "react-icons/fa";
import NewBrandModal from "./NewBrandModal";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { BrandContext } from "./BrandContext";
import Edit from "./assets/icons/Edit";
import { useNavbarContext } from "./context/NavbarContext";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useStore } from "./context/GlobalContext";
import { ConfirmModal } from "./components/ConfirmModal/ConfirmModal";
import { useUpload } from "./UploadContext";

const menuOptions = [
  { to: "/PreMLRReview", label: "Review Content", icon: <Edit /> },
  { to: "/ExistingBrand", label: "Brand Materials", icon: <TfiMenuAlt /> },
  {
    to: "/BrandRules",
    label: "Brand Rules",
    icon: <i className="fas fa-file-alt"></i>,
  },
];

const Navbar = ({ updateBrandOptions }) => {
  const [showNewBrandModal, setShowNewBrandModal] = useState(false);
  const [href, setHref] = useState("");

  const [brandOptions, setBrandOptions] = useState([]);

  const [validationMessage, setValidationMessage] = useState("");

  // Delete modal states
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [brandToDelete, setBrandToDelete] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);


  const navigate = useNavigate();
  const { isOpen } = useNavbarContext();
  const { pathname } = useLocation();
  const { progress, resetGlobalStates, setConfirmation, confirmation } = useStore();
  const { selectedBrand, setSelectedBrand } = useContext(BrandContext);
  const { clinicalUploadsInProgress } = useUpload();
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();

  const cacheKey = "brandOptions";
  const cacheExpiration = 1000 * 60 * 60;


  const fetchBrands = useCallback(async () => {
    if (isAuthenticated) {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        const { options, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp < cacheExpiration) {
          setBrandOptions(options);
          if (updateBrandOptions) updateBrandOptions(options);
          return;
        }
      }

      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/brands?teamId=${roles}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (!response.ok) throw new Error(`Error: ${response.statusText}`);

        const brands = await response.json();
        const options = brands.map((brand) => ({
          value: brand.id,
          label: brand.brandName,
        }));
        setBrandOptions(options);
        if (updateBrandOptions) updateBrandOptions(options);

        localStorage.setItem(
          cacheKey,
          JSON.stringify({ options, timestamp: Date.now() })
        );

        if (
          selectedBrand &&
          !options.find((option) => option.value === selectedBrand.value)
        ) {
          setSelectedBrand(null);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    }
  }, [
    isAuthenticated,
    cacheExpiration,
    updateBrandOptions,
    getAccessTokenSilently,
    getIdTokenClaims,
    selectedBrand,
    setSelectedBrand,
  ]);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const handleBrandSelect = useCallback(
    (selectedOption) => {
      const webViewer = document.getElementById("webViewer");
      const isEditMode = !!webViewer?.offsetHeight;
      const isAnyDocInProgress = progress > 0 || isEditMode;
      if (isAnyDocInProgress) {
        toast.info(
          `You can't change brand while document in ${isEditMode ? "edit" : "progress"
          }.`
        );
        return;
      }
      setSelectedBrand(selectedOption);
      if (pathname.startsWith("/document-history")) {
        navigate("/PreMLRReview");
      }
    },
    [navigate, pathname, progress, setSelectedBrand]
  );

  const openNewBrandModal = () => {
    setShowNewBrandModal(true);
    setValidationMessage("");
  };

  const closeNewBrandModal = () => {
    setShowNewBrandModal(false);
  };

  const handleCreateNewBrand = async (sponsor, brandName) => {
    if (isAuthenticated) {
      const existingBrand = brandOptions.find(
        (option) => option.label.toLowerCase() === brandName.toLowerCase()
      );
      if (existingBrand) {
        setValidationMessage("This brand name already exists.");
        return;
      }

      setValidationMessage("");

      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/brands`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              teamId: roles,
              brandName: brandName,
              sponsor: sponsor,
            }),
          }
        );

        if (!response.ok) throw new Error(`Error: ${response.statusText}`);

        localStorage.removeItem(cacheKey);
        fetchBrands();
        closeNewBrandModal();
        toast.success(`Brand is created!`);
      } catch (error) {
        console.error("Error creating brand:", error);
      }
    }
  };

  const handleDeleteBrand = async () => {
    if (isAuthenticated && brandToDelete) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/delete-brand?teamId=${roles}&brandName=${brandToDelete.label}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        localStorage.removeItem(cacheKey);
        fetchBrands();
        closeDeleteModal();
        toast.success(`${brandToDelete.label} has been deleted!`);
      } catch (error) {
        console.error("Error deleting brand:", error);
      }
    }
  };

  const openDeleteModal = (brand) => {
    setBrandToDelete(brand);
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
    setConfirmationText("");
    setBrandToDelete(null);
  };

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{props.data.label}</span>
          <FaTrash
            onClick={(e) => {
              e.stopPropagation();
              openDeleteModal(props.data);
            }}
          />
        </div>
      </components.Option>
    );
  };


  const handleNavigation = (to) => {
    const webViewer = document.getElementById("webViewer");
    const isEditMode = !!webViewer?.offsetHeight;
    const isNotOpen = pathname !== to;
    // Check if user is editing and hasn't confirmed navigation
    if ((isEditMode || progress > 0 || clinicalUploadsInProgress > 0) && isNotOpen && !confirmation) {
      setConfirmModal(true);
      setHref(to);
      return;
    }

    // If confirmed, navigate directly
    navigate(to);
  };

  const handleConfirm = () => {
    setConfirmation(true)
    setConfirmModal(false);
    navigate(href);
    resetGlobalStates();
    setHref("");
  };


  return (
    <nav
      data-state={isOpen}
      className={`navbar ${isOpen ? "navbar-open" : ""}`}
    >
      <div className="navbar-header">
        <Link to={"/PreMLRReview"}>
          <img
            src="/solstice.png"
            alt="Solstice Logo"
            className="navbar-logo"
          />
        </Link>
      </div>
      <ul className="navbar-nav">
        {menuOptions.map(({ to, label, icon }, index) => (
          <li key={`.${index}`} className="nav-item">
            <span
              onClick={() => handleNavigation(to)}
              className={`nav-link ${pathname.includes(to) ? "active" : ""}`}
            >
              {icon}
              <span>{label}</span>
            </span>
          </li>
        ))}

        <li className="nav-item brand-select-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select
              value={selectedBrand}
              onChange={handleBrandSelect}
              options={brandOptions}
              placeholder="Select a brand"
              className="brand-select"
              components={{ Option: CustomOption }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "#fff",
                  color: "#333",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#333",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "#333",
                }),
              }}
            />
            <button className="add-brand-button" onClick={openNewBrandModal}>
              <FaPlus />
            </button>
          </div>
        </li>
      </ul>

      <NewBrandModal
        isOpen={showNewBrandModal}
        onClose={closeNewBrandModal}
        onSubmit={handleCreateNewBrand}
        validationMessage={validationMessage}
      />

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Confirm Delete"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Are you sure you want to delete this brand?</h2>

        <div className="modal-buttons">
          <button
            className="delete-button"
            onClick={() => {
              handleDeleteBrand();
            }}
          >
            Delete
          </button>
          <button className="cancel-button" onClick={closeDeleteModal}>
            Cancel
          </button>
        </div>
      </Modal>
      {/* Confirm back model */}
      <ConfirmModal
        open={confirmModal}
        setOpen={setConfirmModal}
        onConfirm={handleConfirm}
      />
    </nav>
  );
};

export default Navbar;
