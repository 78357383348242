import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";

function PaginatedItems({ itemsPerPage, setPage, totalRecords = 0 }) {
  const pageCount = Math.ceil(totalRecords / itemsPerPage);

  const handlePageClick = (event) => {
    setPage(event?.selected + 1 ?? 1);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextClassName="page-number next-page"
        nextLabel={<FaChevronRight />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        className={`page-number`}
        activeClassName="active"
        pageClassName="page-count"
        previousClassName="page-number pre-page"
        previousLabel={<FaChevronLeft />}
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default PaginatedItems;
