import { createContext, useContext, useEffect, useRef, useState } from "react";

const NavbarContext = createContext(null);

const NavbarContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <NavbarContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContextProvider;

const useNavbarContext = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error("You cannot use this hook outside of the context");
  }
  return context;
};
export { NavbarContext, useNavbarContext };
