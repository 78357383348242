import React, { useContext, useState } from "react";
import "./Header.css";
import { BrandContext } from "./BrandContext";
import Modal from "react-modal";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown } from "react-icons/fa";
import Toggle from "./assets/icons/Toggle";
import LogoutButton from "./LogoutButton";
import NotificationModal from "./components/NotificationModal/NotificationModal";
import { useNavbarContext } from "./context/NavbarContext";

const Header = () => {
  const { selectedBrand, setSelectedBrand } = useContext(BrandContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const { getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
    setDropdownOpen(false);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
    setConfirmationText("");
  };

  const handleDeleteBrand = async () => {
    if (confirmationText === "I would like to delete") {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/delete-brand?teamId=${roles}&brandName=${selectedBrand.label}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        localStorage.removeItem("brandOptions");

        setSelectedBrand(null); // Update the context to reflect the deletion
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting brand:", error);
      }
    } else {
      alert("Please type 'I would like to delete' to confirm.");
    }
  };
  const { isOpen, setIsOpen } = useNavbarContext();

  return (
    <header className="top-header">
      <div className="header-content">
        <div className="project-title">
          <span
            className={`${isOpen ? "side-open" : ""}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Toggle />
          </span>
          <p>Solstice Health</p>
        </div>
        <div className="notification-menu">
          <NotificationModal />
        </div>
        <div className="user-name">
          <div className="user-menu" onClick={toggleDropdown}>
            <img
              src={`${user.picture ? user.picture : "/profile-icon.png"}`}
              alt="profile-pic"
            />
            <span>{user.name}</span>
            <div className="dropdown">
              <button className="dropdown-toggle">
                <FaChevronDown />
              </button>
              {dropdownOpen && (
                <div className="dropdown-menu">
                  <LogoutButton />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {selectedBrand && (
          <>
            <span>{selectedBrand.label}</span>
            <div className="dropdown">
              <button onClick={toggleDropdown} className="dropdown-toggle">
                &#9660;
              </button>
              {dropdownOpen && (
                <div className="dropdown-menu">
                  <button onClick={openDeleteModal}>Delete</button>
                </div>
              )}
            </div>
          </>
        )} */}
      </div>

      {/* ToastContainer to display notifications globally */}
      <ToastContainer />

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Confirm Delete"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Are you sure you want to delete this brand?</h2>
        <p>
          All work related to this brand will be deleted. Please type 'I would
          like to delete' to confirm.
        </p>
        <input
          type="text"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          placeholder="I would like to delete"
        />
        <div className="modal-buttons">
          <button className="delete-button" onClick={handleDeleteBrand}>
            Delete
          </button>
          <button className="cancel-button" onClick={closeDeleteModal}>
            Cancel
          </button>
        </div>
      </Modal>
    </header>
  );
};

export default Header;
