import React from "react";

const Bell = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
    >
      <path
        d="M6.97094 16.6454C6.316 16.6454 4.27553 16.6454 2.76376 16.6454C1.95828 16.6454 1.43538 15.771 1.7956 15.0279L2.72104 13.1187C3.02194 12.498 3.1786 11.8142 3.1786 11.1202C3.1786 10.2599 3.1786 8.98209 3.1786 7.70417C3.1786 5.46888 4.26213 0.998291 9.67976 0.998291C15.0974 0.998291 16.1809 5.46888 16.1809 7.70417C16.1809 8.98209 16.1809 10.2599 16.1809 11.1202C16.1809 11.8142 16.3376 12.498 16.6385 13.1187L17.5639 15.0279C17.9241 15.771 17.4002 16.6454 16.5948 16.6454H12.3886M6.97094 16.6454C6.97094 18.8806 8.05447 19.9983 9.67976 19.9983C11.305 19.9983 12.3886 18.8806 12.3886 16.6454M6.97094 16.6454C8.66352 16.6454 12.3886 16.6454 12.3886 16.6454"
        stroke="#4E6BF4"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bell;
