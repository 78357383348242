export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  console.log(`Creating Blob with MIME type: ${contentType}`);

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const combineQuad = (quads) => {
  const highlightQuad = quads.reduce(
    (memo, quad) => {
      const x1 = Math.min(quad.p1x, quad.p2x, quad.p3x, quad.p4x);
      const x2 = Math.max(quad.p1x, quad.p2x, quad.p3x, quad.p4x);
      const y1 = Math.min(quad.p1y, quad.p2y, quad.p3y, quad.p4y);
      const y2 = Math.max(quad.p1y, quad.p2y, quad.p3y, quad.p4y);

      memo = {
        x1: Math.min(memo.x1, x1),
        x2: Math.max(memo.x2, x2),
        y1: Math.min(memo.y1, y1),
        y2: Math.max(memo.y2, y2),
      };

      return memo;
    },
    { x1: Infinity, x2: -Infinity, y1: Infinity, y2: -Infinity }
  );

  return highlightQuad;
};

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function sorting({
  arr = [],
  sortParam = "date_uploaded",
  sortOrder = "asc",
}) {

  console.log(arr,sortParam,"sorting");
  
  return [...arr]?.sort((a, b) => {
    if (sortParam === "date_uploaded" || sortParam === "last_updated"|| sortParam==='uploaded_at') {
      if (sortOrder === "asc") {
        return +new Date(a[sortParam]) - +new Date(b[sortParam]);
      } else {
        return +new Date(b[sortParam]) - +new Date(a[sortParam]);
      }
    } else {
      const columA = String(a[sortParam])?.trim()?.toLowerCase();
      const columB = String(b[sortParam])?.trim()?.toLowerCase();

      if (sortOrder === "asc") {
        if (columA < columB) {
          return -1;
        }
        if (columA > columB) {
          return 1;
        }
        return 0;
      } else {
        if (columA > columB) {
          return -1;
        }
        if (columA < columB) {
          return 1;
        }
        return 0;
      }
    }
  });
}

export function bytesToSize(bytes) {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const base = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(base));
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  const formattedSize = (bytes / base ** i).toFixed(1);
  return `${formattedSize} ${sizes[i]}`;
}

// Convert seconds to minutes and seconds
export function getFormattedTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  return `${minutes}`;
}

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}
// show annotation in the ui in the small small batches
export function updateAnnotationsInBatches(
  annotations,
  currentPage,
  batchSize = 50,
  annotationManager
) {
  let index = 0;

  function processBatch() {
    const start = index;
    const end = Math.min(index + batchSize, annotations.length);

    // Process a small batch of annotations at a time
    for (let i = start; i < end; i++) {
      const ann = annotations[i];
      if (ann.PageNumber === currentPage) {
        
        annotationManager.showAnnotation(ann);
      } else {
        annotationManager.hideAnnotation(ann);
      }
    }

    // Update the index and continue processing the next batch
    index += batchSize;

    if (index < annotations.length) {
      // Use requestAnimationFrame to let the UI update and avoid blocking the main thread
      window.requestAnimationFrame(processBatch);
    }
  }

  // Start processing the first batch
  processBatch();
}
