import React from "react";

const Trash = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        fillule="evenodd"
        clipRule="evenodd"
        d="M5.3125 7.96875C5.3125 7.6755 5.5505 7.4375 5.84375 7.4375C6.137 7.4375 6.375 7.6755 6.375 7.96875V14.3438C6.375 14.6375 6.137 14.875 5.84375 14.875C5.5505 14.875 5.3125 14.6375 5.3125 14.3438V7.96875ZM7.96875 7.96875C7.96875 7.6755 8.20675 7.4375 8.5 7.4375C8.79325 7.4375 9.03125 7.6755 9.03125 7.96875V14.3438C9.03125 14.6375 8.79325 14.875 8.5 14.875C8.20675 14.875 7.96875 14.6375 7.96875 14.3438V7.96875ZM10.625 7.96875C10.625 7.6755 10.863 7.4375 11.1562 7.4375C11.4495 7.4375 11.6875 7.6755 11.6875 7.96875V14.3438C11.6875 14.6375 11.4495 14.875 11.1562 14.875C10.863 14.875 10.625 14.6375 10.625 14.3438V7.96875ZM2.65625 14.875C2.65625 16.0485 3.60772 17 4.78125 17H12.2188C13.3923 17 14.3438 16.0485 14.3438 14.875V6.375H2.65625V14.875ZM10.0938 2.125H6.90625V1.59375C6.90625 1.29997 7.14425 1.0625 7.4375 1.0625H9.5625C9.85575 1.0625 10.0938 1.29997 10.0938 1.59375V2.125ZM14.3438 2.125H11.1562V1.0625C11.1562 0.476 10.6803 0 10.0938 0H6.90625C6.31975 0 5.84375 0.476 5.84375 1.0625V2.125H2.65625C2.06975 2.125 1.59375 2.601 1.59375 3.1875V4.25C1.59375 4.8365 2.06921 5.31197 2.65571 5.3125H14.3448C14.9308 5.31197 15.4062 4.8365 15.4062 4.25V3.1875C15.4062 2.601 14.9303 2.125 14.3438 2.125Z"
        fill="black"
      />
    </svg>
  );
};

export default Trash;
