import React from "react";
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import WebViewer from "@pdftron/webviewer";
import { BrandContext } from "../BrandContext";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useUpload } from "../UploadContext"; // Import the useUpload hook

import { jwtDecode } from "jwt-decode";
import {
  b64toBlob,
  bytesToSize,
  debounce,
  updateAnnotationsInBatches,
} from "../utils/helpers";
import { toast } from "react-toastify";
import { COMMON_ERROR_MESSAGE } from "../constant";

import { min, max, flatten } from "lodash";
import Fuse from "fuse.js";

import {
  DocumentAnalysisClient,
  AzureKeyCredential,
} from "@azure/ai-form-recognizer";
import { useStore } from "../context/GlobalContext";
import { useSearchParams } from "react-router-dom";
import AnnotationReasoningDetailModal from "./AnnotationReasoningDetailModal";

const DocumentViewer = forwardRef(({ onSave, isReadOnly, cb }, ref) => {
  const [apryseInstance, setApryseInstance] = useState();
  const apryseInstanceRef = useRef();
  const [azureAnalysisResult, setAzureAnalysisResult] = useState(null);
  const { clinicalUploadsInProgress } = useUpload(); // Use the upload context
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  const isRedirectView = URLSearchParams.get("redirect-view");
  const viewerDiv = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [clinicalFiles, setClinicalFiles] = useState(undefined);
  const [initialDoc, setInitialDoc] = useState("");
  const [guidelinesTaskUUID, setGuidelinesTaskUUID] = useState(null);
  const [rowStatus, setRowStatus] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [llamaParseResult, setLlamaParseResult] = useState(null);

  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const { selectedBrand } = useContext(BrandContext);
  const [fileError, setFileError] = useState("");
  const [brandError, setBrandError] = useState("");
  const [isGuidelinesProcessing, setIsGuidelinesProcessing] = useState(false);
  const [isAnnotationsProcessing, setIsAnnotationsProcessing] = useState(false);

  const [documentUploadedFile, setDocumentUploadedFile] = useState();
  const [annotationCustomData, setAnnotationCustomData] = useState("");

  const {
    progress,
    setProgress,
    uploadedFile,
    setUploadedFile,
    setNotify,
    setReviewData: setDocumentReviewData,
    reviewData: documentReviewData,
    apryseInstanceRef: documnetApryseInstanceRef,
    startProcess,
    setProgressStatus,
    progressStatus,
    setProgressModalIsOpen,
    isAnnotationsCompleted,
    setIsAnnotationsCompleted,
    isGuidelinesCompleted,
    setIsGuidelinesCompleted,
    isReviewContentFailed,
    showViewer,
    setConfirmation,
    setProgressDetail,
  } = useStore();
  const fetchResultTimeOut = useRef(null);

  useEffect(() => {
    const updateWebViewer = async () => {
      if (cb) {
        cb();
      }
      setInitialDoc(uploadedFile);
      setIsAnnotationsCompleted(true);
      setIsGuidelinesCompleted(true);
      setDocumentUploadedFile(uploadedFile);

      if (ref.current) {
        ref.current = {
          ...documnetApryseInstanceRef.current,
          reviewData: documentReviewData,
          apryseInstance: documnetApryseInstanceRef.current,
        };
      }
    };
    if (isRedirectView && uploadedFile && viewerDiv.current) {
      updateWebViewer();
    }
  }, [
    cb,
    documentReviewData,
    documnetApryseInstanceRef,
    isRedirectView,
    ref,
    setIsAnnotationsCompleted,
    uploadedFile,
  ]);

  useEffect(() => {
    if (cb) {
      cb(showViewer);
    }
  }, [cb, showViewer]);

  const uploadToAzure = async (file) => {
    const endpoint = process.env.REACT_APP_AZURE_ENDPOINT;
    const apiKey = process.env.REACT_APP_AZURE_API_KEY;

    if (!endpoint || !apiKey) {
      console.error("Azure credentials are not defined");
      throw new Error("Azure credentials are not defined");
    }

    try {
      console.log("Creating DocumentAnalysisClient...");
      const client = new DocumentAnalysisClient(
        endpoint,
        new AzureKeyCredential(apiKey),
      );

      console.log("Converting file to ArrayBuffer...");
      const arrayBuffer = await file.arrayBuffer();

      console.log("Beginning document analysis...");
      const poller = await client.beginAnalyzeDocument(
        "prebuilt-layout",
        arrayBuffer,
      );

      console.log("Waiting for the analysis to complete...");
      const finalResult = await poller.pollUntilDone();

      console.log(
        "Azure Document Intelligence result:",
        JSON.stringify(finalResult, null, 2),
      );

      if (!finalResult) {
        console.error("Azure Document Intelligence returned null result");
        throw new Error("Null result from Azure Document Intelligence");
      }

      console.log(
        "Number of pages in result:",
        finalResult.pages ? finalResult.pages.length : "undefined",
      );
      console.log(
        "Number of paragraphs:",
        finalResult.paragraphs ? finalResult.paragraphs.length : "undefined",
      );

      if (finalResult.paragraphs) {
        console.log(
          "Sample paragraph:",
          JSON.stringify(finalResult.paragraphs[0], null, 2),
        );
      }

      setAzureAnalysisResult(finalResult);
      return finalResult;
    } catch (error) {
      console.error("Error in uploadToAzure:", error);
      if (error.code === "RestError") {
        toast.error("Azure API Error:", error.message);
        toast.error("Request details:", error.request);
        toast.error("Response details:", error.response);
      } else {
        toast.error("Error in uploadToAzure");
      }
    }
  };

  const checkJobStatus = async (jobId) => {
    const url = `https://api.cloud.llamaindex.ai/api/parsing/job/${jobId}`;
    const apiKey = "llx-XnFIjrenaniAz4vsglAxKDrfPhFoTFpGdblpsz0rC27NjWlQ";

    if (!apiKey) {
      throw new Error("API key is not defined");
    }

    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error checking job status:", error);
      throw error;
    }
  };

  const getJobResult = async (jobId) => {
    const url = `https://api.cloud.llamaindex.ai/api/parsing/job/${jobId}/result/text`;
    const apiKey = "llx-XnFIjrenaniAz4vsglAxKDrfPhFoTFpGdblpsz0rC27NjWlQ";

    if (!apiKey) {
      throw new Error("API key is not defined");
    }

    try {
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      });

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error getting job result:", error);
      throw error;
    }
  };

  const addAnnotationToBoundingBox = (
    boundingBox,
    content,
    pageNumber,
    color = { fill: [0, 0, 255, 0.0], stroke: [255, 0, 0, 1] },
    customData,
    fromRequestAnnotations = false // New parameter
  ) => {
    const { Annotations, annotationManager } = apryseInstanceRef.current.Core;
    try {
      const x1 = boundingBox.x1 * 72;
      const y1 = boundingBox.y1 * 72;
      const x2 = boundingBox.x2 * 72;
      const y2 = boundingBox.y2 * 72;
      console.log("Annotation coordinates:", { x1, y1, x2, y2, pageNumber });
  
      const rectAnnotation = new Annotations.RectangleAnnotation();
      rectAnnotation.PageNumber = pageNumber;
      rectAnnotation.X = x1;
      rectAnnotation.Y = y1;
      rectAnnotation.Width = x2 - x1;
      rectAnnotation.Height = y2 - y1;
      rectAnnotation.FillColor = new Annotations.Color(...color.fill);
      rectAnnotation.StrokeColor = new Annotations.Color(...color.stroke);
      rectAnnotation.Opacity = 0.3;
      rectAnnotation.setContents(content);
  
      // Set fromRequestAnnotations based on the parameter
      rectAnnotation.setCustomData("fromRequestAnnotations", fromRequestAnnotations);
  
      rectAnnotation.setCustomData("customData", customData);
      rectAnnotation.Author = "Solstice Health";
  
      rectAnnotation.Locked = false;
      rectAnnotation.ReadOnly = false;
      rectAnnotation.Listable = true;
      rectAnnotation.Selectable = true;
      rectAnnotation.IsClickableOutsideRect = true;
  
      annotationManager.addAnnotation(rectAnnotation);
      annotationManager.redrawAnnotation(rectAnnotation);
      console.log("Annotation added and redrawn");
    } catch (error) {
      console.error("Error in addAnnotationToBoundingBox:", error);
    }
  };
  console.log(progress);

  const handleFileChange = async (file) => {
    if (clinicalUploadsInProgress > 0) {
      return toast.error(
        "Please wait until the clinical file is done processing",
      );
    }
    // Make the function async
    SetURLSearchParams("");
    if (clinicalUploadsInProgress > 0) {
      toast(
        "Please wait for Clinical Files to finish processing before proceeding ",
        {
          type: "error",
        },
      );
      return;
    }
    setFileError("");
    setBrandError("");
    setConfirmation(false);

    if (!selectedBrand) {
      toast("Please Select Brand ", {
        type: "error",
      });
      return;
    }

    //  if (clinicalFiles.length === 0) {
    //   toast("Upload Clinical Files in the Brand Material Section Before Proceeding ", {
    //     type: "error",
    //   });
    //   return;
    //  }

    if (file && file.type === "application/pdf") {
      try {
        setUploadedFile(file);
        setDocumentUploadedFile(file);
        startProcess();

        const reader = new FileReader();
        reader.onload = function (e) {
          console.log("File loaded", e.target.result); // Log file data URL
          setInitialDoc(e.target.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error during LlamaIndex upload:", error);
      }
    } else {
      toast("Please Upload a PDF", {
        type: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "clinicalInfo",
      JSON.stringify([
        {
          /* clinical info here */
        },
      ]),
    );

    // get file review
    fetch(`${process.env.REACT_APP_BACKEND_APP_API_URL}/api/review`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Review data received", data); // Log API response
        setReviewData(data);
        setDocumentReviewData(data);
      })
      .catch((error) => {
        console.error("Error during API call", error);
      });

    if (clinicalFiles && clinicalFiles.length > 0) {
      // Request guidelines review and check status
      requestGuidelinesReview().then((taskUuid) => {
        if (taskUuid) {
          checkGuidelinesTaskStatus(taskUuid);
        }
      });
    } else {
      console.error(
        "No clinical files available to process guidelines review.",
      );
    }
  };

  const cleanInterval = () => {
    fetchResultTimeOut.current && clearTimeout(fetchResultTimeOut.current);
  };

  const resetState = useCallback(() => {
    setReviewData(null);
    setUploadedFile(null);
    setDocumentUploadedFile(null);
    cleanInterval();
  }, [setUploadedFile]);

  const getAzureAnalysisResult = useCallback(() => {
    return azureAnalysisResult;
  }, [azureAnalysisResult]);

  const fetchClinicalFiles = async () => {
    try {
      if (!isAuthenticated) {
        throw new Error("User is not authenticated");
      }

      if (!selectedBrand) {
        toast("Please select brand", {
          type: "error",
        });
        return [];
      }

      const token = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      const decodedToken = jwtDecode(idToken.__raw);
      const roles = decodedToken["https://solsticehealth.co/roles"];

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/files?teamId=${roles}&brandName=${selectedBrand?.label}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      if (
        data &&
        Array.isArray(data.designFiles) &&
        Array.isArray(data.clinicalFiles)
      ) {
        const getClinicalFilesPromises = data.clinicalFiles.map((file) => {
          return axios
            .get(
              `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/clinical-file?fileId=${file.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then((res) => res.data);
        });

        const clinicalFiles = await Promise.all(getClinicalFilesPromises);

        const files = clinicalFiles.map((file) => {
          const blob = b64toBlob(file.file_content, file.file_type);
          return {
            ...file,
            blob,
          };
        });

        setClinicalFiles(files);
        return files;
      } else {
        toast("No clinical files, please try again later", {
          type: "error",
        });
        return [];
      }
    } catch (error) {
      console.error("Error fetching clinical files:", error);
      toast(COMMON_ERROR_MESSAGE, {
        type: "error",
      });
      return [];
    }
  };

  // Function to handle the guidelines request
  const requestGuidelinesReview = async (fetchedClinicalFiles) => {
    try {
      setIsGuidelinesProcessing(true);

      const guidelinesFormData = new FormData();
      guidelinesFormData.append("drug_marketing_files", documentUploadedFile);

      if (fetchedClinicalFiles && fetchedClinicalFiles.length > 0) {
        fetchedClinicalFiles.forEach((file) => {
          const clinicalFile = new File([file.blob], file.name, {
            type: "application/pdf",
          });
          console.log(`Clinical file type: ${clinicalFile.type}`);
          guidelinesFormData.append("drug_clinical_files", clinicalFile);
        });
      } else {
        console.log("No clinical files available for guidelines review");
      }

      guidelinesFormData.append(
        "clinicalInfo",
        JSON.stringify([
          {
            /* clinical info here */
          },
        ]),
      );

      const token = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      const decodedToken = jwtDecode(idToken.__raw);
      const roles = decodedToken["https://solsticehealth.co/roles"];
      console.log("Roles:", roles);
      console.log("Selected Brand:", selectedBrand?.label);

      // Ensure selectedBrand is not null or undefined before using it
      if (!selectedBrand || !selectedBrand?.label) {
        throw new Error("Selected brand is not defined");
      }

      const rulesResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/rules?teamId=${roles}&brandName=${selectedBrand?.label}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const rulesData = await rulesResponse.data;
      console.log("Fetched rules:", rulesData);

      const formattedRules = {
        rules: rulesData.map((rule) => ({
          rule_title: rule.rule_name,
          rule_description: rule.rule_description,
        })),
      };

      guidelinesFormData.append("llm_rules", JSON.stringify(formattedRules));
      guidelinesFormData.append("team", roles[0]);
      guidelinesFormData.append("brand", selectedBrand?.label);
      const logFormData = (formData) => {
        for (let pair of formData.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
        }
      };

      // Log FormData contents
      logFormData(guidelinesFormData);

      const guidelinesResponse = await axios(
        `https://solstice-health-ai-reviewer-kareem-testing.up.railway.app/guidelines/request`,
        {
          method: "POST",
          headers: {
            "X-Token":
              "vj=$$R^v~f8gf5E57Wp!`s.4J]oj{uW}%:=g,/,S>#@*&)DJj887CdCsw?R)S,1[0",
          },
          data: guidelinesFormData,
        },
      );

      const guidelinesData = await guidelinesResponse.data;
      setGuidelinesTaskUUID(guidelinesData.task_uuid);
      return guidelinesData.task_uuid;
    } catch (error) {
      console.error("Error requesting guidelines review:", error);
      toast.error("Error requesting guidelines review:");
    }
  };

  // Function to search text and add Annotation text to it
  const addAnnotationToText = async (
    text,
    annotationTextContent,
    options = {},
  ) => {
    return new Promise(async (resolve, reject) => {
      try {

        console.log(`Starting to add annotation for text: "${text}"`);
        const { documentViewer, Annotations, PDFNet, annotationManager } =
          apryseInstanceRef.current.Core;
        const doc = await documentViewer.getDocument().getPDFDoc();
        const txtSearch = await PDFNet.TextSearch.create();
        let mode =
          PDFNet.TextSearch.Mode.e_highlight |
          PDFNet.TextSearch.Mode.e_whole_word;

        txtSearch.begin(doc, text, mode);
        let result = await txtSearch.run();
        let foundMatch = false;
        let countResult = 0;

        while (true) {
          if (result.code === PDFNet.TextSearch.ResultCode.e_found) {
            foundMatch = true;
            countResult++;
            if (options.ignoreMultipleResult && countResult > 1) {
              console.log(
                "Multiple results found, ignoring this searchText",
                text,
              );
              break;
            }

            console.log(`Match found for text: "${text}"`);
            let highlights = result.highlights;
            highlights.begin(doc);

            const quads = [];
            const pageNumber = await highlights.getCurrentPageNumber();
            const curPage = await doc.getPage(pageNumber);
            const pageHeight = await curPage.getPageHeight();

            while (await highlights.hasNext()) {
              let quad = await highlights.getCurrentQuads();
              quads.push(quad);
              await highlights.next();
            }

            const { x1, x2, y1, y2 } = combineQuad(flatten(quads));
            const rect = new Annotations.Rect(
              x1,
              pageHeight - y1,
              x2,
              pageHeight - y2,
            );

            const rectAnnotation = new Annotations.RectangleAnnotation();
            rectAnnotation.PageNumber = pageNumber;
            rectAnnotation.X = rect.x1;
            rectAnnotation.Y = rect.y1;
            rectAnnotation.Width = rect.getWidth();
            rectAnnotation.Height = rect.getHeight();

            // Use the color from options, or default to yellow if not provided
            const fillColor = options.color
              ? options.color.fill
              : [0, 0, 255, 0.2];
            const strokeColor = options.color
              ? options.color.stroke
              : [255, 255, 0, 1];
            rectAnnotation.FillColor = new Annotations.Color(...fillColor);
            rectAnnotation.StrokeColor = new Annotations.Color(...strokeColor);
            rectAnnotation.setContents(annotationTextContent);
            rectAnnotation.Opacity = 0.3;
            rectAnnotation.Author = "Solstice Health";

            // Make the annotation editable and movable
            rectAnnotation.Locked = false;
            rectAnnotation.ReadOnly = false;
            rectAnnotation.Listable = true;
            rectAnnotation.Selectable = true;
            rectAnnotation.IsClickableOutsideRect = true;

            annotationManager.addAnnotation(rectAnnotation);
            annotationManager.redrawAnnotation(rectAnnotation);
            console.log("Annotation added and redrawn");

            // Ensure the annotation is fully initialized before adding event listener
            setTimeout(() => {
              annotationManager.selectAnnotation(rectAnnotation);
              rectAnnotation.addEventListener("click", (e) => {
                annotationManager.selectAnnotation(rectAnnotation);
                console.log("Annotation clicked:", rectAnnotation);
                // Implement any additional behavior you want on annotation click
              });
            }, 0);
          } else if (result.code === PDFNet.TextSearch.ResultCode.e_page) {
            console.log("Reached end of page, continuing search");
          } else if (result.code === PDFNet.TextSearch.ResultCode.e_done) {
            console.log("Search completed");
            break;
          }

          result = await txtSearch.run();
        }

        if (!foundMatch) {
          console.warn(`No match found for text: "${text}"`);

          const azureResult = options.azureResult || azureAnalysisResult;

          if (azureResult && azureResult.paragraphs) {
            const allParagraphs = azureResult.paragraphs.map((paragraph) => ({
              content: paragraph.content,
              boundingRegion: paragraph.boundingRegions[0],
              spans: paragraph.spans,
            }));

            console.log("All paragraphs:", allParagraphs);

            const fuse = new Fuse(allParagraphs, {
              keys: ["content"],
              includeScore: true,
            });

            const fuzzyResults = fuse.search(text);
            if (fuzzyResults.length > 0) {
              const bestMatch = fuzzyResults[0].item;
              const polygon = bestMatch.boundingRegion.polygon;
              const boundingBox = {
                x1: Math.min(polygon[0].x, polygon[3].x),
                y1: Math.min(polygon[0].y, polygon[1].y),
                x2: Math.max(polygon[1].x, polygon[2].x),
                y2: Math.max(polygon[2].y, polygon[3].y),
              };
              await addAnnotationToBoundingBox(
                boundingBox,
                annotationTextContent,
                bestMatch.boundingRegion.pageNumber,
                options.color,
                "",
              );
              console.log(
                "Bounding box added using Azure Document Intelligence results with fuzzy matching",
              );
            } else {
              console.warn(
                `No bounding box found for text: "${text}" in Azure Document Intelligence results using fuzzy matching`,
              );
            }
          } else {
            console.error("Invalid Azure Document Intelligence result format");
          }
        }

        resolve();
      } catch (error) {
        console.error("Error in addAnnotationToText:", error);
        reject(error);
      }
    });
  };

  const processGuidelinesAnnotations = async (
    notAlignedStatements,
    azureResult,

  ) => {
    if (!notAlignedStatements || !Array.isArray(notAlignedStatements)) return;

    for (const statement of notAlignedStatements) {
      const { problem_text, comment } = statement;
      if (comment) {
        try {
          if (problem_text && problem_text.trim() !== "") {
            // If problem_text exists and is not just whitespace
            await addAnnotationToText(problem_text, "FDA REG: " + comment, {
              azureResult,
              color: { fill: [255, 0, 0, 0.2], stroke: [255, 0, 0, 1] },
            });
          } else {
            // If problem_text is null, undefined, an empty string, or just whitespace
            await addAnnotationToTopOfDocument(comment);
          }
        } catch (error) {
          console.error(
            "Error adding annotation for statement:",
            statement,
            error,
          );
        }
      }
    }
  };

  // Function to fetch guidelines results
  const fetchGuidelinesResults = async () => {
    if (guidelinesTaskUUID) {
      setIsGuidelinesProcessing(true);
      try {
        const response = await fetch(
          `https://solstice-health-ai-reviewer-kareem-testing.up.railway.app/guidelines/results/full_details?task_uuid=${guidelinesTaskUUID}`,
          {
            method: "POST",
            headers: {
              "X-Token":
                "vj=$$R^v~f8gf5E57Wp!`s.4J]oj{uW}%:=g,/,S>#@*&)DJj887CdCsw?R)S,1[0",
            },
          },
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        console.log("Guidelines results:", data);

        if (data.task_status === "Processing") {
          setTimeout(fetchGuidelinesResults, 5000);
        } else if (data.task_status === "Failed") {
          toast("Guidelines processing failed", {
            type: "error",
          });
          setIsGuidelinesProcessing(false);
        } else {
          setReviewData(data);
          setDocumentReviewData(data);
          setIsGuidelinesProcessing(false);
          setIsGuidelinesCompleted(true);

          // Ensure Azure analysis result is available
          let azureResult = azureAnalysisResult;
          if (!azureResult) {
            console.log(
              "No Azure analysis result found. Uploading to Azure...",
            );
            azureResult = await uploadToAzure(documentUploadedFile);
            console.log("Uploaded to Azure!", azureResult);
            setAzureAnalysisResult(azureResult);
          }

          // Process the guideline results and add annotations
          console.log(data.task_not_aligned_statements_combined_filtered);
          processGuidelinesAnnotations(
            data.task_not_aligned_statements_combined_filtered,
            azureResult,
          );
        }
      } catch (error) {
        console.error("Error fetching guidelines results:", error);
        setIsGuidelinesProcessing(false);
        toast("Guidelines processing failed", {
          type: "error",
        });
      }
    }
  };

  const addAnnotationToTopOfDocument = async (comment) => {
    const { Annotations, annotationManager } = apryseInstanceRef.current.Core;
    const documentViewer = apryseInstanceRef.current.Core.documentViewer;

    // Get the first page
    const firstPage = 1;
    const pageInfo = await documentViewer.getDocument().getPageInfo(firstPage);
    // Create a rectangle annotation at the top of the first page
    const rectAnnotation = new Annotations.RectangleAnnotation();
    rectAnnotation.PageNumber = firstPage;
    rectAnnotation.X = 20; // Small margin from the left
    rectAnnotation.Y = pageInfo.height - 100; // Small margin from the top
    rectAnnotation.Width = pageInfo.width - 40; // Width of the page minus margins
    rectAnnotation.Height = 80; // Fixed height for the annotation
    // rectAnnotation.FillColor = new Annotations.Color(173, 216, 230, 1);
    rectAnnotation.StrokeColor = new Annotations.Color(255, 0, 0, 0.2);
    rectAnnotation.Opacity = 0.0;
    rectAnnotation.setContents("FDA REG: " + comment);
    rectAnnotation.Author = "Solstice Health";

    // Make the annotation editable and movable
    rectAnnotation.Locked = false;
    rectAnnotation.ReadOnly = false;
    rectAnnotation.Listable = true;
    rectAnnotation.Selectable = true;
    rectAnnotation.IsClickableOutsideRect = true;

    annotationManager.addAnnotation(rectAnnotation);
    annotationManager.redrawAnnotation(rectAnnotation);
  };

  // Function to check guidelines task status
  const checkGuidelinesTaskStatus = async (guidelinesTaskUUID, retries = 5) => {
    try {
      const response = await fetch(
        `https://solstice-health-ai-reviewer-kareem-testing.up.railway.app/guidelines/results/full_details?task_uuid=${guidelinesTaskUUID}`,
        {
          method: "POST",
          headers: {
            "X-Token":
              "vj=$$R^v~f8gf5E57Wp!`s.4J]oj{uW}%:=g,/,S>#@*&)DJj887CdCsw?R)S,1[0",
          },
        },
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Guidelines response:", data);
      setProgressStatus((pre) => ({
        ...pre,
        guidelines: data.task_status,
      }));

      if (data.task_status === "Complete") {
        console.log("Guidelines task is complete.");
        setReviewData(data);
        setDocumentReviewData(data);
        setIsGuidelinesProcessing(false); // Set guidelines processing to false
        setIsGuidelinesCompleted(true); // Set guidelines completed to true

        console.log("done from guidelines");
        console.log("Guidelines task data", data);
        console.log(data?.task_not_aligned_statements_combined_filtered);
        let azureResult = azureAnalysisResult;

        if (!azureResult) {
          console.log("No Azure analysis result found. Uploading to Azure...");
          azureResult = await uploadToAzure(documentUploadedFile);
          console.log("Uploaded to Azure!", azureResult);
          setAzureAnalysisResult(azureResult);
        } // Process the guideline results and add annotations

        processGuidelinesAnnotations(
          data.task_not_aligned_statements_combined_filtered,
          azureResult,
        );
      } else if (data.task_status === "Failed") {
        console.log("Guidelines task has failed.");
        toast("Guidelines processing failed", {
          type: "error",
        });
        setIsGuidelinesProcessing(false); // Set guidelines processing to false

        setIsGuidelinesCompleted(false); // Set guidelines completed to false
      } else {
        console.log(
          "Guidelines task not completed yet, checking again in 5 seconds...",
        );
        fetchResultTimeOut.current = setTimeout(() => {
          if (!isReviewContentFailed) {
            checkGuidelinesTaskStatus(guidelinesTaskUUID);
          }
        }, 5000); // Check every 5 seconds
      }
    } catch (error) {
      if (!retries) {
        setProgressStatus((pre) => ({
          ...pre,
          guidelines: "Failed",
        }));
      }

      if (retries > 0) {
        setTimeout(
          () => checkGuidelinesTaskStatus(guidelinesTaskUUID, retries - 1),
          5000,
        ); // Retry after 5 seconds
      } else {
        toast("Error checking guidelines task status", {
          type: "error",
        });
        console.error("Error checking guidelines task status:", error);
        setIsGuidelinesProcessing(false); // Set guidelines processing to false
        setIsGuidelinesCompleted(false); // Set guidelines completed to false
        toast("Guidelines processing failed", {
          type: "error",
        });
      }
    }
  };

  // Function to request annotation and get task UUID
  const requestAnnotation = async (clinicalFilesToUse) => {
    const formData = new FormData();

    if (clinicalFilesToUse && clinicalFilesToUse.length > 0) {
      clinicalFilesToUse.forEach((file) => {
        const clinicalFile = new File([file.blob], file.name, {
          type: "application/pdf",
        });
        formData.append("drug_clinical_files", clinicalFile);
      });
    }

    formData.append("drug_marketing_files", documentUploadedFile); // Get the token and extract the team ID

    const token = await getAccessTokenSilently();
    const idToken = await getIdTokenClaims();
    const decodedToken = jwtDecode(idToken.__raw);
    const roles = decodedToken["https://solsticehealth.co/roles"];

    formData.append("team", roles[0]);
    formData.append("brand", selectedBrand?.label); // Log the entire formData

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const annotationResponseData = await axios.post(
        `${process.env.REACT_APP_BASE_API}/annotations/request`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Token":
              "vj=$$R^v~f8gf5E57Wp!`s.4J]oj{uW}%:=g,/,S>#@*&)DJj887CdCsw?R)S,1[0",
          },
        },
      );
      const { task_uuid: taskUuid } = annotationResponseData.data;
      return taskUuid;
    } catch (error) {
      console.error(
        "Error in requestAnnotation:",
        error.response ? error.response.data : error.message,
      );
    }
  };
  const addServerAnnotations = useCallback(async (annotationResponse) => {
    const annotationResults = annotationResponse.results;
  
    for (const result of annotationResults) {
      console.log("Processing result:", result);
      const { statement, polygon, page_number } = result;
  
      if (polygon && polygon.length === 4) {
        const [x1, y1] = polygon[0];
        const [x2, y2] = polygon[2];
  
        // Existing scale back factor
        const scaleBack = 1 / 1.7;
  
        // Additional adjustment factors
        const adjustX = 0.85; // Slightly reduce width
        const adjustY = 0.85; // Reduce height more significantly
        const offsetX = 0; // Move left (in pixels)
        const offsetY = 0; // Move up (in pixels)
  
        const boundingBox = {
          x1: (Math.min(x1, x2) * scaleBack * adjustX + offsetX) / 72,
          y1: (Math.min(y1, y2) * scaleBack * adjustY + offsetY) / 72,
          x2: (Math.max(x1, x2) * scaleBack * adjustX + offsetX) / 72,
          y2: (Math.max(y1, y2) * scaleBack * adjustY + offsetY) / 72,
        };
  
        if (result.annotations_details && result.annotations_details.length > 0) {
          for (const detail of result.annotations_details) {
            const annotationText = `File Name: ${detail.file_name}\n\nPage: ${detail.page_number_in_file} \n\nSource Text: "${detail.annotation_text}"`;
            const reasoning = detail.reasoning;
            const stringfyData = JSON.stringify({ text: statement, reasoning });
  
            try {
              await addAnnotationToBoundingBox(
                boundingBox,
                annotationText,
                parseInt(page_number),
                { fill: [0, 0, 255, 0.0], stroke: [0, 0, 255, 1] },
                stringfyData,
                true // Set fromRequestAnnotations to true
              );
              console.log("Annotation added successfully for detail:", detail);
            } catch (error) {
              console.error("Error adding annotation for detail:", detail, error);
            }
          }
        } else {
          // No annotations_details for this statement
          const annotationText = "Annotation Needed";
          const reasoning = null; // No reasoning available
          const stringfyData = JSON.stringify({ text: statement, reasoning });
  
          try {
            await addAnnotationToBoundingBox(
              boundingBox,
              annotationText,
              parseInt(page_number),
              { fill: [255, 0, 0, 0.0], stroke: [255, 0, 0, 1] },
              stringfyData,
              false // Set fromRequestAnnotations to true
            );
            console.log(
              "Annotation added successfully for statement with no annotations_details"
            );
          } catch (error) {
            console.error(
              "Error adding annotation for statement with no annotations_details",
              error
            );
          }
        }
      } else {
        console.warn("Invalid polygon data for statement:", statement);
      }
    }
  }, []);
  

  const checkTaskStatus = async (taskUuid, retries = 5) => {
    try {
      setIsAnnotationsProcessing(true); // Set annotations processing to true

      const annotationResponse = await axios
        .post(
          `${process.env.REACT_APP_BASE_API}/annotations/results`,
          undefined,
          {
            params: {
              task_uuid: taskUuid,
            },
            headers: {
              "X-Token":
                "vj=$$R^v~f8gf5E57Wp!`s.4J]oj{uW}%:=g,/,S>#@*&)DJj887CdCsw?R)S,1[0",
            },
          },
        )
        .then((response) => response.data);
      console.log("Annotation response:", annotationResponse);
      setProgressStatus((pre) => ({
        ...pre,
        annotations: annotationResponse.task_status,
      }));
      const progressIndicator = annotationResponse?.progress_indicator;
      const totalProgress = progressIndicator?.progress_percentage;
      const currentStep = progressIndicator?.current_step;
      const currentMessage = progressIndicator?.current_message;
      setProgress(totalProgress);
      setProgressDetail({ currentMessage, currentStep });
      if (annotationResponse.task_status === "Complete") {
        console.log(
          "Task is complete. Performing Azure analysis before adding server annotations.",
        );
        // Perform Azure analysis
        let azureResult = azureAnalysisResult;

        if (!azureResult) {
          console.log("No Azure analysis result found. Uploading to Azure...");
          azureResult = await uploadToAzure(documentUploadedFile);
          console.log("Uploaded to Azure!", azureResult);
          setAzureAnalysisResult(azureResult);
        }
        // Add server annotations with both annotation response and Azure result

        setIsAnnotationsProcessing(false);
        setIsAnnotationsCompleted(true);
        await addServerAnnotations(annotationResponse, azureResult);
      } else if (annotationResponse.task_status === "Failed") {
        console.log("Task has failed.");
        toast("Something went wrong. Please try again later.", {
          type: "error",
        });
        setIsAnnotationsProcessing(false); // Set annotations processing to false

        setIsAnnotationsCompleted(false); // Set annotations completed to false
      } else {
        console.log("Task not completed yet, checking again in 1 second...");
        fetchResultTimeOut.current = setTimeout(() => {
          if (!isReviewContentFailed) {
            checkTaskStatus(taskUuid);
          }
        }, 1000); // Check every second
      }
    } catch (error) {
      if (!retries) {
        setProgressStatus((pre) => ({
          ...pre,
          annotations: "Failed",
        }));
        setProgressModalIsOpen(false);
      }
      if (retries > 0) {
        setTimeout(() => {
          if (!isReviewContentFailed) {
            checkTaskStatus(taskUuid, retries - 1);
          }
        }, 1000); // Retry after 1 second
      } else {
        console.error("Error checking task status:", error);
        toast.error(
          "There is getting error while checking Annotation task status",
        );
        setIsAnnotationsProcessing(false); // Set annotations processing to false
        setIsAnnotationsCompleted(false); // Set annotations completed to false
      }
    }
  };
  // console.log(progressStatus);

  const combineQuad = (quads) => {
    const highlightQuad = quads.reduce((memo, quad) => {
      const x1 = min([quad.p1x, quad.p2x, quad.p3x, quad.p4x]);
      const x2 = max([quad.p1x, quad.p2x, quad.p3x, quad.p4x]);
      const y1 = min([quad.p1y, quad.p2y, quad.p3y, quad.p4y]);
      const y2 = max([quad.p1y, quad.p2y, quad.p3y, quad.p4y]);

      memo = {
        x1: min([memo.x1, x1]),
        x2: max([memo.x2, x2]),
        y1: min([memo.y1, y1]),
        y2: max([memo.y2, y2]),
      };

      return memo;
    }, {});

    return highlightQuad;
  };
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);

  const handleDetail = (event) => {
    const { annotationManager } = apryseInstanceRef.current.Core;

    const button = event.target;

    // Retrieve the annotation ID stored in the data attribute
    const annotationId = button.getAttribute("data-annotation-id");

    if (annotationId) {
      // Get the annotation by its ID
      const annotation = annotationManager.getAnnotationById(annotationId);

      if (annotation) {
        // Get the contents of the annotation
        const annotationContents = annotation.getCustomData("customData");
        let parsedAnnotationData;

        if (annotationContents) {
          parsedAnnotationData = JSON.parse(annotationContents)
        } else {
          parsedAnnotationData = {}
        }

        setAnnotationCustomData(parsedAnnotationData);
        setDetailModalIsOpen(true);
      } else {
        console.error("Annotation not found for ID:", annotationId);
      }
    } else {
      console.error("No annotation ID found for the clicked button.");
    }
  };

  const initializeViewer = async () => {
    if (
      initialDoc &&
      viewerDiv.current &&
      (reviewData || isRedirectView) &&
      !apryseInstance
    ) {
      localStorage.removeItem("persist:search-default");
      localStorage.removeItem("persist:viewer-default");

      console.log("Initializing WebViewer...");
      setIsLoading(true);

      if (viewerDiv.current?.UI) {
        await viewerDiv.current.UI.dispose();
      }
      try {
        const instance = await WebViewer(
          {
            path: "/lib",
            fullAPI: true,
            licenseKey: process.env.REACT_APP_APRYSE_KEY,
            css: "/index.css",
          },
          viewerDiv.current,
        );

        instance.Core.documentViewer.loadDocument(initialDoc);
        const { Feature } = instance.UI;

        instance.UI.disableFeatures([
          Feature.LocalStorage,
          Feature.PageNavigation,
        ]);

        instance.UI.setAnnotationContentOverlayHandler((annotation) => {
          // remove annotation pop-up
          return null;
        });
        instance.UI.openElements(["notesPanel"]);
        instance.UI.disableElements([
          "notesPanelHeader",
          "toolbarGroup-Shapes",
          "toolbarGroup-Insert",
          "toolbarGroup-Edit",
          "toolbarGroup-FillAndSign",
          "toolbarGroup-Forms",
        ]);

        setApryseInstance(instance);
        apryseInstanceRef.current = instance;
        documnetApryseInstanceRef.current = instance;
        const iframeDoc = instance.UI.iframeWindow.document;

        const zoomOverlay = iframeDoc.querySelector("#app");
        zoomOverlay.classList.add("custom-viewer");

        instance.UI.addEventListener(
          instance.UI.Events.VISIBILITY_CHANGED,
          async (event) => {
            const isOpen = await instance.UI.isElementOpen("notesPanel");
            if (isOpen) {
              const notesPanel = iframeDoc.querySelector(".NotesPanel");
              
              if (notesPanel) {
                const saveButton = iframeDoc.querySelector("#saveButton");
                /**Check if save button exist in iframe if not exist then create */
                if (!saveButton) {
                  notesPanel.classList.add("custom-notePanel");
                  const button = document.createElement("button");
                  button.classList.add("save-button-custom");
                  button.onclick = handleSave;
                  button.textContent = "Save";
                  button.id = "saveButton";
                  notesPanel.appendChild(button);
                }
              }
            }
          },
        );

        const { documentViewer, annotationManager } = instance.Core;
        annotationManager.setCurrentUser(user?.nickname);

        if (isRedirectView) {
          return;
        }

        documentViewer.addEventListener("documentLoaded", async () => {

          await new Promise((resolve) => setTimeout(resolve, 500));
          documentViewer.setFitMode(documentViewer.FitMode.FitWidth);
          setIsAnnotationsProcessing(true); // Set annotations processing to true
          setIsGuidelinesProcessing(true);
          setProgressModalIsOpen(true);
          setProgress(1);

          const fetchedClinicalFiles = await fetchClinicalFiles();

          setClinicalFiles(fetchedClinicalFiles);

          const annotationTaskUuid = await requestAnnotation(
            fetchedClinicalFiles,
          );
          await checkTaskStatus(annotationTaskUuid);

          const guidelinesTaskUUID = await requestGuidelinesReview(
            fetchedClinicalFiles,
          );

          await checkGuidelinesTaskStatus(guidelinesTaskUUID);

          setIsLoading(false);
          setIsSaveDisabled(false);

          const pageNavOverlay = iframeDoc.querySelector(".PageNavOverlay");

          if (pageNavOverlay) {
            const addLoading = (currentPage) => {
              const prePage = currentPage > 1 ? currentPage - 1 : currentPage;
              if (prePage !== currentPage) {
                const app = iframeDoc.getElementById("app");
                const loaderContainer = document.createElement("div");
                const loaderDiv = document.createElement("span");
                loaderContainer.classList.add("loading-next-page");
                loaderContainer.append(loaderDiv);
                const contentContainer = iframeDoc.querySelector(".App");
                app.insertBefore(loaderContainer, contentContainer);
                setTimeout(() => {
                  app.removeChild(loaderContainer);
                }, 2000);
              }
            };
            const preButton = iframeDoc.querySelector(
              "[aria-label='Previous page']",
            );
            const nextButton = iframeDoc.querySelector(
              "[aria-label='Next page']",
            );

            preButton.addEventListener("click", (e) => {
              const currentPage = documentViewer.getCurrentPage();
              if (currentPage > 1) {
                addLoading(currentPage);

                instance.Core.documentViewer.setCurrentPage(currentPage - 1);
              }
            });

            nextButton.addEventListener("click", (e) => {
              e.preventDefault();
              const currentPage = documentViewer.getCurrentPage();
              const totalPages = documentViewer.getPageCount(); // Retrieve total page count
              if (currentPage < totalPages) {
                const updatedPageNumber = currentPage + 1;
                addLoading(updatedPageNumber);
                instance.Core.documentViewer.setCurrentPage(currentPage, true);
              }
            });
          }
        });

        documentViewer.addEventListener("documentLoadFailed", (error) => {
          console.error("Document load failed", error);
          setIsLoading(false);
        });

        // Debounced function to handle page changes
        function onPageChange() {
          const currentPage = documentViewer.getCurrentPage();
          const annotations = annotationManager.getAnnotationsList();
          // Batch render annotations
          updateAnnotationsInBatches(
            annotations,
            currentPage,
            50,
            annotationManager,
          );
        }

        documentViewer.addEventListener(
          "pageNumberUpdated",
          debounce(onPageChange, 50), // Debounced page change handler
        );

        // Initial rendering of annotations
        annotationManager.addEventListener(
          "annotationChanged",
          debounce((annotations) => {
            const currentPage = documentViewer.getCurrentPage();
            updateAnnotationsInBatches(
              annotations,
              currentPage,
              50,
              annotationManager,
            );
          }, 50),
        );

        instance.UI.addEventListener(
          instance.UI.Events.VISIBILITY_CHANGED,
          async (event) => {
            const isOpen = await instance.UI.isElementOpen("notesPanel");
            if (isOpen) {
              const notesPanels = iframeDoc.querySelectorAll(".Note");
        
              if (notesPanels.length) {
                const annotationManager = instance.Core.annotationManager;
        
                for (let i = 0; i < notesPanels.length; i++) {
                  const element = notesPanels[i];
        
                  // Extract the annotation ID from the note panel's ID
                  const notePanelId = element.getAttribute('id');
                  console.log("NOTE PANEL ID", notePanelId);
                  if (!notePanelId) continue;
        
                  // The ID is in the format 'note_<annotationId>'
                  const annotationId = notePanelId.replace('note_', '');
                  console.log("ANNOTATION ID", annotationId);
                  if (!annotationId) continue;
                  console.log("ANNOTATIONS LIST", annotationManager.getAnnotationsList());

                  
        
                  // Get the annotation using the ID
                  const annotation = annotationManager.getAnnotationById(annotationId);
                  console.log("ANNOTATION", annotation);
                  if (!annotation) continue;
        
                  // Check if the annotation is from request annotations
                  const isFromRequestAnnotations = annotation.getCustomData("fromRequestAnnotations") === 'true';
                  console.log("IS FROM REQUEST ANNOTATIONS", isFromRequestAnnotations);
                  if (isFromRequestAnnotations) {
                    const saveButton = element.querySelector("#modal-see-reasons");
        
                    if (!saveButton) {
                      element.classList.add("custom-notePanel");
        
                      const button = document.createElement("button");
                      button.classList.add("see-button-custom", "see-reasons");
                      button.onclick = handleDetail;
                      button.textContent = "See Reasoning";
                      button.id = "modal-see-reasons";
        
                      // Store the annotation ID as a data attribute on the button
                      button.setAttribute("data-annotation-id", annotationId);
        
                      const dateTimeDiv = element.querySelector(".NoteContent");
        
                      if (dateTimeDiv) {
                        dateTimeDiv.parentNode.appendChild(button);
                      }
                    }
                  }
                }
              }
            }
          }
        );
        

        documentViewer.addEventListener("annotationsLoaded", () => {
          const currentPage = documentViewer.getCurrentPage();
          const annotations = annotationManager.getAnnotationsList();
          updateAnnotationsInBatches(
            annotations,
            currentPage,
            50,
            annotationManager,
          );
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error initializing WebViewer:", error);
      }
    }
  };

  useEffect(() => {
    initializeViewer();
  }, [isAnnotationsCompleted, initializeViewer]);

  useEffect(() => {
    if (reviewData && reviewData.not_aligned_statements) {
      const allReviewed =
        reviewData.not_aligned_statements.length === 0 ||
        reviewData.not_aligned_statements.every(
          (_, idx) => rowStatus[idx] !== undefined,
        );
      setIsSaveDisabled(!allReviewed);
    } else {
      setIsSaveDisabled(true);
    }
  }, [rowStatus, reviewData]);

  useImperativeHandle(
    ref,
    () => {
      return {
        apryseInstance,
        reviewData,
        resetState,
        documentUploadedFile,
      };
    },
    [apryseInstance, resetState, reviewData, documentUploadedFile],
  );

  const handleRowStatus = (index, status) => {
    setRowStatus((prevRowStatus) => {
      const newRowStatus = { ...prevRowStatus, [index]: status };
      const allReviewed = reviewData.not_aligned_statements.every(
        (_, idx) => newRowStatus[idx] !== undefined,
      );
      setIsSaveDisabled(!allReviewed);
      return newRowStatus;
    });
  };

  const handleSave = () => {
    if (isAnnotationsProcessing || isGuidelinesProcessing) {
      toast("Please wait for all reviews and checks before saving.", {
        type: "error",
      });
      return;
    }

    onSave();
  };

  const viewerDefaultStyle = {
    height: `${showViewer ? "600px" : "0px"}`,
    width: `${showViewer ? "auto" : "0px"}`,
    border: "0px",
  };
  if (showViewer) {
    delete viewerDefaultStyle.border;
  }

  return (
    <Fragment>
      {brandError && <p className="error-message">{brandError}</p>}
      {fileError && <p className="error-message">{fileError}</p>}

      <div
        id="webViewer"
        className="webViewer"
        ref={viewerDiv}
        style={viewerDefaultStyle}
      />

      {!showViewer ? (
        <label
          htmlFor="file-input"
          className="file-drop"
          onDrop={(event) => {
            event.preventDefault();
            if (
              event.dataTransfer.files &&
              event.dataTransfer.files.length > 0
            ) {
              const file = event.dataTransfer.files[0];
              handleFileChange(file);
            }
          }}
          onDragOver={(event) => event.preventDefault()}
        >
          <div className="file-drop-content">
            <div className="file-drop-zone">
              <div className="file-drop-icon">
                <img src="/file-upload-icon.png" alt="File Drop Icon" />
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                id="file-input"
                onChange={(event) => {
                  if (event.target.files && event.target.files.length > 0) {
                    const file = event.target.files[0];
                    handleFileChange(file);
                  }
                }}
                accept="application/pdf"
              />
              <div className="file-label">
                <span className="file-label-text">
                  Please upload existing content below to review
                </span>
              </div>
            </div>
          </div>
        </label>
      ) : null}

      <AnnotationReasoningDetailModal
        isOpen={detailModalIsOpen}
        onClose={() => setDetailModalIsOpen(false)}
        data={annotationCustomData}
      />
    </Fragment>
  );
});

export default DocumentViewer;