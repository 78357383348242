import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useContext,
} from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import { FaCheck, FaChevronLeft, FaTrashAlt } from "react-icons/fa";
import "./PreMLRReview.css";
import { jwtDecode } from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BrandContext } from "./BrandContext";
import Modal from "react-modal";

import DocumentViewer from "./components/DocumentViewer";
import { File as FileIcon } from "./assets/icons/File";
import Calendar from "./assets/icons/Calendar";
import Edit from "./assets/icons/Edit";
import Action from "./assets/icons/Action";
import DeleteModal from "./components/DeleteModal";
import { useStore } from "./context/GlobalContext";
import TableLoader from "./components/TableLoader/TableLoader";
import { RxCross2 } from "react-icons/rx";
import { useNavbarContext } from "./context/NavbarContext";
import LoaderSpin from "./components/LoaderSpin/LoaderSpin";
import PaginatedItems from "./components/PaginatedItems/PaginatedItems";
import { ConfirmModal } from "./components/ConfirmModal/ConfirmModal";
import axios from "axios";
import { Select } from "@chakra-ui/react";
import { sorting } from "./utils/helpers";

const PreMLRReview = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [previousDocuments, setPreviousDocuments] = useState([]);
  const [latestVersionDates, setLatestVersionDates] = useState({});
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [rowStatus, setRowStatus] = useState({});
  const [documentName, setDocumentName] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [documentsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [eventLoading, setEventLoading] = useState({
    save: false,
    delete: false,
  });
  const [loading, setLoading] = useState(false);
  const [documentViewerKey, setDocumentViewerKey] = useState(0);
  const [hideTable, setHideTable] = useState(false);

  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const { selectedBrand } = useContext(BrandContext);
  const { isOpen } = useNavbarContext();
  const navigate = useNavigate();
  const documentViewerRef = useRef();
  const [_, setUrlSearchParams] = useSearchParams();

  const {
    reviewData: documentReviewData,
    apryseInstanceRef: documnetApryseInstanceRef,
    resetGlobalStates,
    
  } = useStore();

  const handleDelete = (document) => {
    setDocumentToDelete(document);
    setDeleteModalIsOpen(true);
  };

  const confirmDelete = async () => {
    if (documentToDelete) {
      setEventLoading((pre) => ({ ...pre, delete: true }));
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        console.log(
          `Deleting file with documentId: ${documentToDelete.id}, teamId: ${roles}`
        );

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/delete-document?documentId=${documentToDelete.id}&teamId=${roles}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        setDeleteModalIsOpen(false);
        setDocumentToDelete(null);
        fetchPreviousDocuments();
      } catch (error) {
        console.error("Error deleting document:", error);
      } finally {
        setEventLoading((pre) => ({ ...pre, delete: false }));
      }
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const resetState = () => {
    if (documentViewerRef.current && documentViewerRef.current.apryseInstance) {
      const { documentViewer } = documentViewerRef.current.apryseInstance.Core;
      documentViewer.getDocument().unloadResources();
      documentViewerRef.current.apryseInstance.UI.loadDocument(null);
    }

    setRowStatus({});
    setDocumentName("");
    setUploadedFile(null); // Add this line to clear the uploaded file state
    setReviewData(null); // Add this line to clear the review data state
    fetchPreviousDocuments();
  };

  const handleSave = () => {
    openModal();
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleModalSave = async () => {
    const { reviewData: divReviewData } = documentViewerRef.current;
    const reviewData = divReviewData ?? documentReviewData;
  
    if (documentName && reviewData) {
      setEventLoading((pre) => ({ ...pre, save: true }));
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];
        const teamId = Array.isArray(roles) ? roles[0] : roles;
        if (!documentViewerRef.current.apryseInstance) {
          throw new Error("WebViewer is not initialized");
        }
        const { apryseInstance: documentApryseInstance } = documentViewerRef.current;
        const apryseInstance = documentApryseInstance ?? documnetApryseInstanceRef.current;
        const { documentViewer, annotationManager } = apryseInstance.Core;
        if (!documentViewer.getDocument()) {
          throw new Error("No document is loaded");
        }
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations();
        const data = await doc.getFileData({
          xfdfString,
        });
        const blob = new Blob([data], { type: "application/pdf" });
        const fileObject = new File([blob], "annotated_document.pdf", {
          type: "application/pdf",
        });
        const formData = new FormData();
        formData.append("file", fileObject);
        formData.append("user_id", user.email);
        formData.append("document_name", documentName);
        formData.append("team_id", teamId);
        formData.append("brand_name", selectedBrand.label);
        formData.append("comments", "");
        const documentResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/documents`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        if (!documentResponse.ok) {
          throw new Error(`Error: ${documentResponse.statusText}`);
        }
        const documentData = await documentResponse.json();
        const documentId = documentData.id;
        const versionFormData = new FormData();
        versionFormData.append("document_id", documentId);
        versionFormData.append("version", "1");
        versionFormData.append("file", fileObject);
        versionFormData.append("review_date", new Date().toISOString());
        versionFormData.append("reviewed_by", user.email);
        const versionResponse = await axios(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/document-versions`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: versionFormData,
          }
        );
        const versionData = versionResponse.data;
        const versionId = versionData.id;

        await axios(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/version-comments`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify({
              version_id: versionId,
              comments: "",
            }),
          }
        );

        setModalIsOpen(false);
        handleBackClick();
        setSuccessModalIsOpen(true);
        closeModal();
        resetState();
      } catch (error) {
        console.error("Error saving document and comments:", error);
      } finally {
        setEventLoading((pre) => ({ ...pre, save: false }));
      }
    }
  };
  const fetchPreviousDocuments = useCallback(async () => {
    if (isAuthenticated && selectedBrand) {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/previous-documents?teamId=${roles}&brandName=${selectedBrand.label}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setPreviousDocuments(data);
      } catch (error) {
        console.error("Error fetching previous documents:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [
    isAuthenticated,
    selectedBrand,
    getAccessTokenSilently,
    getIdTokenClaims,
  ]);

  const fetchLatestVersionDates = useCallback(async () => {
    if (isAuthenticated && selectedBrand) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken["https://solsticehealth.co/roles"];

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_APP_API_URL}/api/latest-version-dates?teamId=${roles}&brandName=${selectedBrand.label}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setLatestVersionDates(data);
      } catch (error) {
        console.error("Error fetching latest version dates:", error);
      }
    }
  }, [
    isAuthenticated,
    selectedBrand,
    getAccessTokenSilently,
    getIdTokenClaims,
  ]);

  useEffect(() => {
    fetchPreviousDocuments();
  }, [fetchPreviousDocuments]);

  useEffect(() => {
    if (previousDocuments && previousDocuments.length > 0) {
      const documentIds = previousDocuments.map((doc) => doc.id);
      fetchLatestVersionDates(documentIds);
    }
  }, []);

  useEffect(() => {
    setPreviousDocuments((prevDocuments) =>
      prevDocuments.slice().sort((a, b) => {
        const dateA = latestVersionDates[a.id]
          ? new Date(latestVersionDates[a.id])
          : new Date(0);
        const dateB = latestVersionDates[b.id]
          ? new Date(latestVersionDates[b.id])
          : new Date(0);
        return dateB - dateA;
      })
    );
  }, [latestVersionDates]);

  const filteredDocuments = previousDocuments.filter((doc) =>
    doc.document_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );

  const handleDocumentClick = (documentId) => {
    navigate(`/document-history/${documentId}`);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCallback =useCallback((value) => {
    setHideTable(value);
  },[]);

  const handleBackClick = () => {
    setConfirmModal(false);
    setHideTable(false);
    setDocumentViewerKey((pre) => pre + 1);
    setUrlSearchParams("");
    resetGlobalStates();
  };


  const handleSort = (e) => {
    const sortOrder = e.target.value

    const sortedData = sorting({ arr: previousDocuments, sortParam: "uploaded_at", sortOrder })

    setPreviousDocuments(sortedData)

  }


  return (
    <div className={`content_container ${!isOpen ? "container-expend" : ""}`}>
      <Header />
      <Navbar />

      <div className="container">
        <div className={`document-viewer ${hideTable ? "expend-viewer" : ""}`}>
          <div className="pre-mlr-review-header">
            {hideTable ? (
              <button
                className="gradient-btn back-btn no-icon"
                onClick={() => setConfirmModal(true)}
              >
                <span>
                  <FaChevronLeft />
                </span>
                Back
              </button>
            ) : null}
            <h2>Review Your Content</h2>
          </div>
          <DocumentViewer
            key={documentViewerKey}
            cb={handleCallback}
            ref={documentViewerRef}
            onSave={handleSave}
          />
        </div>

        {!hideTable ? (
          <div className="document-table">
            <div className="table-section">
              <div className="table-header-content">
                <h2>Previously Reviewed Documents</h2>
                <div className="table-filters">
                  <div className="dropdown">
                    <label>Sort by :</label>
                    <Select placeholder="Select option" onChange={handleSort} defaultValue={''}>
                      <option value="desc">Latest</option>
                      <option value="asc">Oldest</option>
                    </Select>
                  </div>
                  <input
                    type="text"
                    placeholder="Search documents"
                    value={searchQuery}
                    onChange={({ target: { value } }) => setSearchQuery(value)}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="material-table">
                  <thead>
                    <tr>
                      <th>
                        <FileIcon />
                        Document Name
                      </th>
                      <th>
                        <Calendar />
                        Date Created
                      </th>
                      <th>
                        <Calendar />
                        Last Reviewed
                      </th>
                      <th>
                        <Edit />
                        Created By
                      </th>
                      <th>
                        <Action />
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      currentDocuments.map((doc, index) => (
                        <tr key={`.${index}`}>
                          <td>{doc.document_name}</td>
                          <td>
                            {new Date(doc.uploaded_at).toLocaleDateString()}
                          </td>
                          <td>
                            {latestVersionDates[doc.id]
                              ? new Date(
                                latestVersionDates[doc.id]
                              ).toLocaleDateString()
                              : new Date(doc.uploaded_at).toLocaleDateString()}
                          </td>
                          <td>{doc.user_id}</td>
                          <td>
                            <div className="action-buttons">
                              <button
                                className="delete-button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(doc);
                                }}
                              >
                                <FaTrashAlt />
                              </button>
                              <button
                                className="edit-button"
                                onClick={(e) => {
                                  handleDocumentClick(doc.id);
                                }}
                              >
                                <Edit color="#000" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <TableLoader totalLoaders={10} />
                    )}
                  </tbody>
                </table>
              </div>
              {/* Pagination */}
              <div className="pagination">
                <PaginatedItems
                  itemsPerPage={documentsPerPage}
                  setPage={paginate}
                  totalRecords={filteredDocuments.length}
                />
              </div>
            </div>
          </div>
        ) : null}

        {/* confirm save model */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Save Document"
          className="new-brand-modal"
          overlayClassName="new-brand-modal-overlay"
        >
          <div className="modal-logo">
            <img src="/solstice.png" alt="logo" />{" "}
          </div>
          <h2>Save Document</h2>
          <input
            type="text"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            placeholder="Enter document name"
          />
          <div className="modal-buttons">
            <button
              disabled={eventLoading.save}
              className="save-button"
              onClick={handleModalSave}
            >
              {eventLoading.save ? (
                <LoaderSpin style={{ height: "30px", width: "30px" }} />
              ) : (
                <>
                  <FaCheck />
                  Save
                </>
              )}
            </button>
            <button className="cancel-button" onClick={closeModal}>
              <RxCross2 /> Cancel
            </button>
          </div>
        </Modal>

        {/* Success modal */}
        <Modal
          isOpen={successModalIsOpen}
          onRequestClose={() => setSuccessModalIsOpen(false)}
          contentLabel="Success"
          className="new-brand-modal"
          overlayClassName="new-brand-modal-overlay"
        >
          <h2>Success</h2>
          <p>Document and comments saved successfully!</p>
          <div className="modal-buttons">
            <button
              className="save-button"
              onClick={() => setSuccessModalIsOpen(false)}
            >
              OK
            </button>
          </div>
        </Modal>

        {/* Delete model */}
        <DeleteModal
          isOpen={deleteModalIsOpen}
          onConfirm={confirmDelete}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={eventLoading.delete}
        />

        {/* Confirm back model */}
        <ConfirmModal
          open={confirmModal}
          setOpen={setConfirmModal}
          onConfirm={handleBackClick}
        />
      </div>
    </div>
  );
};

export default PreMLRReview;
