import React from 'react'

const Toggle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="21"
      viewBox="0 0 29 21"
      fill="none"
    >
      <rect
        width="29"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 29 0)"
        fill="black"
      />
      <rect
        width="22.1765"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 29 9)"
        fill="#4E6BF4"
      />
      <rect
        width="29"
        height="3"
        rx="1.5"
        transform="matrix(-1 0 0 1 29 18)"
        fill="black"
      />
      <path
        d="M-1.30491e-07 10.5L3.83824 7.46891L3.83824 13.5311L-1.30491e-07 10.5Z"
        fill="#4E6BF4"
      />
    </svg>
  );
}

export default Toggle